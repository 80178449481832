import { useState } from 'react';
import { Box, Flex, Icon } from '@chakra-ui/react';
import { cloneDeep } from 'lodash';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

import { CheckIcon, DragHandleIcon } from '@inspire/ui/icons';

const reorder = (list, startIndex, endIndex) => {
  const result = cloneDeep(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const SortElements = ({ setValues, sortOptions }) => {
  const [orderedList, setOrderedList] = useState([...sortOptions]);

  const handleDragEnd = ({ source, destination }: DropResult) => {
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }
    const newList = reorder(orderedList, source.index, destination.index);
    const result = newList.map((item) => ({ ...item }));
    setOrderedList(result);
    setValues(result);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="topics">
        {(droppableProvided) => (
          <Box
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            {orderedList?.length > 0 &&
              orderedList.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(draggableProvided) => (
                    <Flex
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      ref={draggableProvided.innerRef}
                      align="center"
                      py={1}
                    >
                      <Icon as={DragHandleIcon} mr={2} />
                      {item.label}
                      {item.isCorrect && (
                        <Icon
                          as={CheckIcon}
                          ml={2}
                          color="green.500"
                          boxSize={5}
                        />
                      )}
                    </Flex>
                  )}
                </Draggable>
              ))}
            {droppableProvided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};
