import { Radio, Text } from '@chakra-ui/react';
import {
  initFormikValuesFromSchema,
  validateSchema,
} from '@inspire/data/helpers/schemas';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { useMyProfile, useUser } from 'lib/hooks';
import { RadioGroupControl, TextareaControl } from 'formik-chakra-ui';
import {
  AidesEtudiantes,
  AidesMobilites,
  PretsEtudiants,
  TravaillerPendantSesEtudes,
} from 'components/kit-de-survie/vers-le-sup';
import { TypesLogement } from 'components/kit-de-survie/logement';
import { RecapRadio, RecapTextArea } from 'components/activites/recap-helpers';

const demenagerOptions = {
  oui: 'Oui',
  non: 'Non',
  ouvert: 'J’y suis ouvert',
  neSaisPas: 'Je ne sais pas',
};

export const MePreparerALaVieEtudiante = ({ goNext }) => {
  const { isProf } = useUser();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(
    'vieEtudianteDemenager',
    'vieEtudianteTypeLogement',
    'vieEtudianteAideDemenagement',
    'vieEtudianteFinancement',
    'vieEtudianteQuestionsAuxEclaireurs'
  );

  const formik: FormikConfig<FormikValues> = {
    initialValues: initFormikValuesFromSchema(schema, myProfile),
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(values),
  };

  return (
    <Formik {...formik}>
      {({ values }) => (
        <Form>
          <RadioGroupControl
            name="vieEtudianteDemenager"
            label="Souhaites-tu déménager pour tes études ?"
            stackProps={{
              direction: ['column', null, 'column'],
              spacing: [1, null, 3],
            }}
            isRequired
            mb="10"
          >
            {Object.entries(demenagerOptions).map(([key, label]) => (
              <Radio key={`demenagerOptions_${key}`} value={key}>
                {label}
              </Radio>
            ))}
          </RadioGroupControl>

          <Text textAlign="center" fontStyle={'italic'}>
            Aide-toi des informations à disposition pour répondre aux questions
            suivantes{' '}:
          </Text>
          <TypesLogement />
          <AidesMobilites />

          <TextareaControl
            label="Quel type de logement te conviendrait le plus ?"
            name="vieEtudianteTypeLogement"
            isRequired={true}
            mb="10"
            mt="10"
          />

          <TextareaControl
            label="Quelle aide peux-tu solliciter si tu souhaites déménager ?"
            name="vieEtudianteAideDemenagement"
            isRequired={true}
            mb="10"
          />

          <Text textAlign="center" fontStyle={'italic'}>
            Aide-toi des informations à disposition pour répondre aux questions
            suivantes{' '}:
          </Text>
          <AidesEtudiantes />
          <PretsEtudiants />
          <TravaillerPendantSesEtudes />

          <TextareaControl
            label="Cite une manière de financer tes études qui pourrait te correspondre :"
            name="vieEtudianteFinancement"
            isRequired={true}
            mb="10"
            mt="10"
          />

          <TextareaControl
            label="Pour creuser la définition de ton projet, tu peux lister ici des questions que tu pourras poser à un Étudiant éclaireur :"
            name="vieEtudianteQuestionsAuxEclaireurs"
            isRequired={false}
            mb="10"
          />

          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={
              isProf ||
              (values?.vieEtudianteDemenager &&
                values?.vieEtudianteTypeLogement &&
                values?.vieEtudianteAideDemenagement &&
                values?.vieEtudianteFinancement)
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export const RecapMePreparerALaVieEtudiante = ({ profile }) => (
  <>
    <RecapRadio
      fieldName="vieEtudianteDemenager"
      header="Souhaites-tu déménager pour tes études ?"
      options={demenagerOptions}
      profile={profile}
    />

    <RecapTextArea
      header="Quel type de logement te conviendrait le plus ?"
      fieldName="vieEtudianteTypeLogement"
      profile={profile}
    />

    <RecapTextArea
      header="Quelle aide peux-tu solliciter si tu souhaites déménager ?"
      fieldName="vieEtudianteAideDemenagement"
      profile={profile}
    />

    <RecapTextArea
      header="Cite une manière de financer tes études qui pourrait te correspondre :"
      fieldName="vieEtudianteFinancement"
      profile={profile}
    />

    <RecapTextArea
      header="Pour creuser la définition de ton projet, tu peux lister ici des questions que tu pourras poser à un Étudiant éclaireur :"
      fieldName="vieEtudianteQuestionsAuxEclaireurs"
      profile={profile}
    />
  </>
);
