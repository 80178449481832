import { Box } from '@chakra-ui/react';
import { TextAreaActivite } from './TextareaActivite';
import { RecapTextArea } from 'components/activites/recap-helpers';

export const MesExperiences = (props) => (
  <TextAreaActivite
    fieldName="derniereExperienceStage"
    label="Raconte ta dernière expérience de stage"
    isRequired={true}
    {...props}
  />
);

export const RecapMesExperiences = ({ profile }) => (
  <Box>
    <RecapTextArea
      profile={profile}
      fieldName="derniereExperienceStage"
      header="Mon expérience de stage"
    />
  </Box>
);
