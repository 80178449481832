import { Box, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { validateSchema } from '@inspire/data/helpers/schemas';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { useMyProfile, usePistes, useUser } from 'lib/hooks';
import { TextareaControl } from 'formik-chakra-ui';
import { take } from 'lodash';
import { getPistesReco } from '@inspire/data/helpers/algo';
import Link from 'components/general/Link';
import { pistePath } from 'lib/paths';
import PisteItem from 'components/piste/PisteItem';
import { SortElements } from 'components/activites/SortElements';

interface formationComparaisonItemInterface {
  pisteId: string;
  debouches: string;
  duree: string;
  cout: string;
  experiencePro: [string];
  correspond: string;
  correspondpas: string;
  order: number;
}

export type formationComparaisonsInterface = {
  [key: number]: formationComparaisonItemInterface;
};

const formatValues = (values) => {
  return {
    formationComparaisons: Object.values(
      values.formationComparaisons as formationComparaisonsInterface
    ),
  };
};

export const ComparerFormationsMesChoix = ({ goNext }) => {
  const { isProf } = useUser();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const { pistes } = usePistes();

  let pistesReco = [];

  if (pistes) {
    if (myProfile?.formationComparaisons?.length) {
      const pisteIds = myProfile.formationComparaisons.map(
        (value) => value.pisteId
      );
      pistesReco = pistes.filter((piste) => pisteIds.includes(piste._id));
    } else {
      pistesReco = isProf
        ? take(pistes, 3)
        : take(getPistesReco(myProfile.algoResults, pistes), 3);
    }
  }

  const schema = LyceenBacProProfileSchema.pick(
    'formationComparaisons',
    'formationComparaisons.$'
  ).requireFields();

  const initialValues = {
    formationComparaisons: {},
  };

  const pistesSortOptions = [];

  pistesReco.map((piste, index) => {
    const previousValue = myProfile?.formationComparaisons?.find(
      (element) => element.pisteId === piste._id
    );
    initialValues.formationComparaisons[piste._id] = {
      pisteId: piste._id,
      duree: previousValue?.duree,
      cout: previousValue?.cout,
      experiencePro: previousValue?.experiencePro,
      debouches: previousValue?.debouches,
      correspond: previousValue?.correspond || '',
      correspondpas: previousValue?.correspondpas || '',
      order: previousValue?.order >= 0 ? previousValue?.order : index,
    };

    pistesSortOptions.push({
      id: piste._id,
      label: piste.name,
      order: previousValue?.order >= 0 ? previousValue?.order : index,
    });
  });

  pistesSortOptions.sort((a, b) => a.order - b.order);

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, formatValues(values)),
    onSubmit: (values) => setAndRefetchMyProfile(formatValues(values)),
  };

  return (
    <Formik {...formik}>
      {({ values, setFieldValue }) => {
        const setOrderedPistes = (pistes) =>
          pistes.map((piste, index) => {
            setFieldValue(`formationComparaisons[${piste.id}].order`, index);
          });

        return (
          <Form>
            <Text textAlign="center">
              Consulte tes pistes recommandées par INSPIRE et répond aux
              questions.
            </Text>

            <SimpleGrid
              columns={{ sm: 1, lg: 3 }}
              spacing="30px"
              mt="10"
              mb="10"
            >
              {pistesReco.map((piste) => (
                <Box key={`piste_${piste.slug}`}>
                  <Link
                    href={pistePath(piste.slug)}
                    style={{ textDecoration: 'none' }}
                    target="_blank"
                  >
                    <PisteItem piste={piste} showGauge={false} />
                  </Link>
                </Box>
              ))}
            </SimpleGrid>

            <Heading as={'h2'} fontSize={20}>
              Pour faire le point :
            </Heading>

            <Text>
              - Qu’est-ce qui te correspond dans ces pistes proposées ?{' '}
              <span style={{ color: 'red' }}>*</span>
            </Text>

            <SimpleGrid columns={{ sm: 1, lg: 3 }} spacing="30px" mb="10">
              {pistesReco.map((piste) => (
                <TextareaControl
                  key={`formationComparaisons_${piste._id}_correspond`}
                  name={`formationComparaisons[${piste._id}].correspond`}
                  label={piste.name}
                  labelProps={{
                    textAlign: 'center',
                    fontWeight: 'normal',
                    minHeight: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  isRequired
                  display="flex"
                  flexDirection="column"
                  alignItems="stretch"
                  mb={5}
                />
              ))}
            </SimpleGrid>

            <Text>
              - Qu’est-ce qui te correspond moins dans ces pistes ?{' '}
              <span style={{ color: 'red' }}>*</span>
            </Text>

            <SimpleGrid columns={{ sm: 1, lg: 3 }} spacing="30px" mb="10">
              {pistesReco.map((piste) => (
                <Box key={`correspondpas_piste_${piste.slug}`}>
                  <TextareaControl
                    key={`formationComparaisons_${piste._id}_correspondpas`}
                    name={`formationComparaisons[${piste._id}].correspondpas`}
                    label={piste.name}
                    labelProps={{
                      textAlign: 'center',
                      fontWeight: 'normal',
                      minHeight: '50px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    isRequired
                    display="flex"
                    flexDirection="column"
                    alignItems="stretch"
                    mb={5}
                  />
                </Box>
              ))}
            </SimpleGrid>

            <Text fontWeight={'bold'}>
              Place dans ton ordre de préférence les formations qui
              t’intéressent en lien avec ton projet. ?{' '}
              <span style={{ color: 'red' }}>*</span>
            </Text>

            <Box maxWidth={'400px'} margin="auto" pos="relative">
              <SortElements
                setValues={setOrderedPistes}
                sortOptions={pistesSortOptions}
              />
            </Box>

            <FormActiviteFooter
              isLoading={isSettingAndRefetchingMyProfile}
              allowNext={
                isProf ||
                formatValues(values).formationComparaisons.filter(
                  (value) =>
                    value.pisteId && value.correspond && value.correspondpas
                ).length === 3
              }
            />
          </Form>
        );
      }}
    </Formik>
  );
};
