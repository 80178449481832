import {
  Box,
  Icon,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Text,
} from '@chakra-ui/react';

import { FileImageIcon, FileTextIcon } from '@inspire/ui/icons';
import ClickableImage from 'components/general/ClickableImage';
import { DefinitionBox } from 'components/general/DefinitionBox';
import Euros from 'components/general/Euros';
import Link from 'components/general/Link';
import {
  GTM_CATEGORY_KIT_DE_SURVIE,
  GTM_ACTION_VOIR_RUBRIQUE,
  pushEvent,
} from 'lib/gtm';
import InfographieDemenagerSrc from '../../../public/img/infographies/demenager.png';

export const TypesLogement = () => (
  <>
    <Heading size="sm">Les types de logement</Heading>
    <Accordion allowMultiple>
      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Studio / Logement individuel'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Studio / Logement individuel
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Studio / Logement individuel">
            <Heading size="xs">✓ Qu’est-ce que c’est </Heading>
            <Text>
              Location d’un appartement individuel, souvent un studio pour les
              étudiants, auprès d’un particulier ou d’une agence immobilière.
            </Text>
            <Heading size="xs">✓ Où chercher </Heading>
            <Text>
              <Link href="Particulier-Particulier" isExternal>
                De Particulier à Particulier
              </Link>
              ,{' '}
              <Link href="https://www.leboncoin.fr/" isExternal>
                Leboncoin
              </Link>
              ,{' '}
              <Link
                href="https://www.seloger.com/immobilier/locations/ville/bien-appartement/"
                isExternal
              >
                SeLoger
              </Link>
              ,{' '}
              <Link href="https://www.lokaviz.fr/" isExternal>
                Lokaviz
              </Link>
            </Text>
            <Heading size="xs">✓ Combien ça coûte </Heading>
            <Text>
              Entre <Euros>300</Euros> et <Euros>700</Euros> selon la région.
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Colocation'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Colocation
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Colocation">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              Une colocation c’est le fait de partager un logement privé à
              plusieurs.
            </Text>
            <Heading size="xs">✓ Où chercher ?</Heading>
            <Text>
              <Link href="https://www.appartager.com/" isExternal>
                Appartager
              </Link>
              ,{' '}
              <Link href="https://www.lacartedescolocs.fr/" isExternal>
                La carte des colocs
              </Link>
              ,{' '}
              <Link href="https://www.leboncoin.fr/" isExternal>
                Leboncoin
              </Link>
            </Text>
            <Heading size="xs">✓ Combien ça coûte ?</Heading>
            <Text>
              Environ <Euros>400</Euros> par mois pour une chambre individuelle
              et <Euros>200</Euros> pour une chambre partagée, les prix varient
              selon la région.
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Cohabitation intergénérationnelle'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Cohabitation intergénérationnelle
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Cohabitation intergénérationnelle">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              Il s’agit d’une formule d’hébergement à moindre frais chez une
              personne âgée, en échange parfois de services, travail ou juste
              pour de la compagnie.{' '}
            </Text>
            <Heading size="xs">✓ Où chercher ?</Heading>
            <Text>
              <Link href="www.reseau-cosi.org" isExternal>
                www.reseau-cosi.org
              </Link>
              ,{' '}
              <Link href="www.ensemble2generations.fr" isExternal>
                www.ensemble2generations.fr
              </Link>{' '}
            </Text>
            <Heading size="xs">✓ Combien ça coûte ?</Heading>
            <Text>
              De la gratuité à un loyer modéré en fonction des obligations.
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Résidence du CROUS pour les étudiants'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Résidence du CROUS pour les étudiants
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Résidence du CROUS pour les étudiants">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              Les logements du CROUS sont destinés aux élèves à faibles
              ressources. Ils sont attribués en fonction de l’éloignement
              géographique, des moyens de la famille et du jeune, de la
              composition de la famille, etc.
            </Text>
            <Heading size="xs">✓ Où faire sa demande ?</Heading>
            <Text>
              Remplir son Dossier Social Étudiant (DSE) en ligne sur :
              <Link href="https://www.messervices.etudiant.gouv.fr" isExternal>
                {' '}
                www.messervices.etudiant.gouv.fr
              </Link>
            </Text>
            <Heading size="xs">✓ Combien ça coûte ?</Heading>
            <Text>
              Entre <Euros>200</Euros> et <Euros>500</Euros> par mois, selon la
              région.
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Résidence étudiante privée'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Résidence étudiante privée
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Résidence étudiante privée">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              Les résidences étudiantes privées proposent aux étudiants des
              studios, 2 pièces ou colocations, accompagnés d’espaces communs et
              de services (ex : laverie, salle de sport, etc.), proche des lieux
              d’études.
            </Text>
            <Heading size="xs">✓ Où faire sa demande ?</Heading>
            <Text>
              <Link href="https://www.estudines.com/" isExternal>
                Les Estudines
              </Link>
              ,{' '}
              <Link href="https://www.studelites.com/fr/" isExternal>
                Studélites
              </Link>
              ,{' '}
              <Link href="https://www.fac-habitat.com/fr/" isExternal>
                Fac-Habitat
              </Link>
            </Text>
            <Heading size="xs">✓ Combien ça coûte ?</Heading>
            <Text>
              Entre <Euros>300</Euros> et <Euros>700</Euros> par mois.
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Foyer pour les étudiants et jeunes travailleurs'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Foyer pour les étudiants et jeunes travailleurs
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Foyer pour les étudiants et jeunes travailleurs">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              Le foyer pour les étudiant et les jeunes travailleurs est une
              résidence qui héberge des jeunes de 16 ans à 30 ans maximum. Le
              jeune peut être salarié, stagiaire, apprenti, étudiant ou en
              recherche d'emploi. Le résident signe un contrat d'occupation avec
              le gestionnaire du foyer.
            </Text>
            <Heading size="xs">✓ Où chercher ?</Heading>
            <Text>
              <Link href="https://www.aljt.com/" isExternal>
                Association pour le Logement des Jeunes Travailleurs
              </Link>{' '}
              (ALJT)
              <Link href="http://www.arfj.asso.fr/" isExternal>
                Association des Résidences et Foyers de Jeunes
              </Link>{' '}
              (ARFJ){' '}
              <Link href="http://www.unme-asso.com/" isExternal>
                Union Nationale des Maisons d'Étudiants
              </Link>{' '}
              (UNME){' '}
              <Link href="http://www.unhaj.org/" isExternal>
                Union Nationale pour l’Habitat des Jeunes
              </Link>{' '}
              (UNHAJ)
            </Text>
            <Heading size="xs">✓ Combien ça coûte ?</Heading>
            <Text>
              Entre <Euros>300</Euros> et <Euros>500</Euros> par mois, selon la
              région.
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Internat pour les étudiants'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Internat pour les étudiants
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Internat pour les étudiants">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              Les internats sont directement dans les lycées. Les places sont
              réservées pour des étudiants en BTS ou classe préparatoire, et
              sont généralement attribuées en priorité aux étudiants ayant des
              revenus modestes. Les chambres sont individuelles ou partagées.
            </Text>
            <Heading size="xs">✓ Où faire sa demande ?</Heading>
            <Text>
              <Link href="http://www.internat.education.gouv.fr/" isExternal>
                http://www.internat.education.gouv.fr/
              </Link>{' '}
              et faire une demande avec internat dans ses voeux sur{' '}
              <Link href="http://www.parcoursup.fr" isExternal>
                Parcoursup
              </Link>
              .
            </Text>
            <Heading size="xs">✓ Combien ça coûte ?</Heading>
            <Text>
              Entre <Euros>150</Euros> et <Euros>200</Euros> par mois, selon la
              région.
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  </>
);

const KitDeSurvieLogementPage = () => {
  return (
    <>
      <TypesLogement />

      <Heading size="sm">Déménager</Heading>
      <Accordion allowMultiple>
        <AccordionItem>
          <AccordionButton
            onClick={() => {
              pushEvent(
                GTM_CATEGORY_KIT_DE_SURVIE,
                GTM_ACTION_VOIR_RUBRIQUE,
                'Infographie déménager en 8 étapes'
              );
            }}
          >
            <Icon as={FileImageIcon} mr={2} />
            <Box flex="1" textAlign="left">
              Infographie déménager en 8 étapes
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel py={2}>
            <Box maxWidth="500px" margin="auto">
              <ClickableImage
                src={InfographieDemenagerSrc}
                href="/img/infographies/regles-or-PFM.png"
                alt="Infographie déménager en 8 étapes"
              />
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Heading size="sm">Les aides au logement</Heading>

      <Accordion allowMultiple>
        <AccordionItem>
          <AccordionButton
            onClick={() => {
              pushEvent(
                GTM_CATEGORY_KIT_DE_SURVIE,
                GTM_ACTION_VOIR_RUBRIQUE,
                'APL'
              );
            }}
          >
            <Icon as={FileTextIcon} mr={2} />
            <Box flex="1" textAlign="left">
              APL
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel py={2}>
            <DefinitionBox title="APL">
              <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
              <Text>
                Les aides personnalisées au logement sont distribuées par la
                caisse d’allocations familiales (CAF) et destinées à réduire le
                montant des loyers. L’APL peut être demandée pour une location
                ou colocation.
              </Text>
              <Heading size="xs">✓ Où faire sa demande ?</Heading>
              <Text>
                La demande se fait sur le site :{' '}
                <Link href="http://www.caf.fr/" isExternal>
                  http://www.caf.fr/
                </Link>
              </Text>
              <Heading size="xs">✓ Quel montant ?</Heading>
              <Text>
                Généralement entre <Euros>100</Euros> et <Euros>200</Euros> par
                mois, le montant varie en fonction de plusieurs critères
                (boursier, colocation, revenus, etc.).
              </Text>
            </DefinitionBox>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton
            onClick={() => {
              pushEvent(
                GTM_CATEGORY_KIT_DE_SURVIE,
                GTM_ACTION_VOIR_RUBRIQUE,
                'Garantie Visale'
              );
            }}
          >
            <Icon as={FileTextIcon} mr={2} />
            <Box flex="1" textAlign="left">
              Garantie Visale
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel py={2}>
            <DefinitionBox title="Garantie Visale">
              <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
              <Text>
                La garantie Visale est un dispositif d’aide au logement, gratuit
                pour les 30 ans et moins, quelle que soit sa situation
                professionnelle y compris étudiant et alternant (possible aussi
                pour les plus de 30 ans salariés sous conditions de revenu).
                L’Etat se porte garant pour un locataire. Cette aide renforce la
                solidité des dossiers et vous aide à trouver rapidement un
                logement.
              </Text>
              <Heading size="xs">✓ Où faire sa demande ?</Heading>
              <Text>
                <Link
                  href="https://www.actionlogement.fr/la-garantie-visale"
                  isExternal
                >
                  https://www.actionlogement.fr/la-garantie-visale
                </Link>
              </Text>
            </DefinitionBox>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton
            onClick={() => {
              pushEvent(
                GTM_CATEGORY_KIT_DE_SURVIE,
                GTM_ACTION_VOIR_RUBRIQUE,
                'Avance Loca-Pass'
              );
            }}
          >
            <Icon as={FileTextIcon} mr={2} />
            <Box flex="1" textAlign="left">
              Avance Loca-Pass
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel py={2}>
            <DefinitionBox title="Avance Loca-Pass">
              <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
              <Text>
                L’avance Loca-pass est un prêt à taux 0 qui permet de financer
                le dépôt de garantie exigé par le propriétaire d’un logement.
                Cette avance est remboursable en 25 mois maximum.
              </Text>
              <Heading size="xs">✓ Où faire sa demande ?</Heading>
              <Text>
                <Link href="https://locapass.actionlogement.fr/" isExternal>
                  https://locapass.actionlogement.fr/
                </Link>
              </Text>
            </DefinitionBox>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton
            onClick={() => {
              pushEvent(
                GTM_CATEGORY_KIT_DE_SURVIE,
                GTM_ACTION_VOIR_RUBRIQUE,
                'Mon job Mon logement'
              );
            }}
          >
            <Icon as={FileTextIcon} mr={2} />
            <Box flex="1" textAlign="left">
              Mon job Mon logement
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel py={2}>
            <DefinitionBox title="Mon job Mon logement">
              <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
              <Text>
                Si vous venez de signer un nouveau contrat de travail et que
                vous percevez moins de 1,5 fois le SMIC brut vous pouvez
                prétendre à l’aide Mon Job, Mon Logement, qui vise à faciliter
                le rapprochement domicile-travail. Il faut être locataire du
                nouveau logement depuis moins de 3 mois au moment de la demande.
              </Text>
              <Heading size="xs">✓ Quel montant ?</Heading>
              <Text>
                Cette aide s’élève à <Euros>1 000</Euros>
              </Text>
              <Heading size="xs">✓ Où faire sa demande ?</Heading>
              <Link
                href="https://www.actionlogement.fr/aide-mon-job-mon-logement"
                isExternal
              >
                https://www.actionlogement.fr/aide-mon-job-mon-logement
              </Link>
            </DefinitionBox>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton
            onClick={() => {
              pushEvent(
                GTM_CATEGORY_KIT_DE_SURVIE,
                GTM_ACTION_VOIR_RUBRIQUE,
                'Aide pour le loyer mobili-jeune (alternant)'
              );
            }}
          >
            <Icon as={FileTextIcon} mr={2} />
            <Box flex="1" textAlign="left">
              Aide pour le loyer mobili-jeune (alternant)
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel py={2}>
            <DefinitionBox title="Aide pour le loyer mobili-jeune (alternant)">
              <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
              <Text>
                L’aide mobili-jeune est adressée aux alternants de moins de 30
                ans en contrat d’apprentissage (ou de professionnalisation) qui
                travaillent dans une entreprise du secteur privé. Elle est
                octroyée par Action Logement et a pour but de prendre en charge
                une partie du loyer.
              </Text>
              <Heading size="xs">✓ Quel montant ?</Heading>
              <Text>
                Entre <Euros>10</Euros> et <Euros>100</Euros> par mois. Elle est
                versée pendant toute la formation en alternance et pour une
                durée maximale de 3 ans.
              </Text>
              <Heading size="xs">✓ Où faire sa demande ?</Heading>
              <Text>
                <Link
                  href="https://www.actionlogement.fr/l-aide-mobili-jeune"
                  isExternal
                >
                  https://www.actionlogement.fr/l-aide-mobili-jeune
                </Link>
              </Text>
            </DefinitionBox>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default KitDeSurvieLogementPage;
