import { ReactElement, ReactNode } from 'react';

import { Box, BoxProps, Heading } from '@chakra-ui/react';

interface Props {
  title: string;
  children: ReactElement | ReactNode;
}

export const DefinitionBox = ({
  title,
  children,
  ...boxProps
}: Props & BoxProps) => (
  <Box
    bg="gray.100"
    px={6}
    py={5}
    borderRadius={4}
    mb={8}
    mx={'auto'}
    width={['100%', '80%', '66%']}
    {...boxProps}
  >
    <Heading size="md" textAlign="center">
      {title}
    </Heading>
    {children}
  </Box>
);
