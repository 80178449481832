import { Box, Checkbox, Text } from '@chakra-ui/react';
import { validateSchema } from '@inspire/data/helpers/schemas';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import { FieldArray, Form, Formik, FormikConfig, FormikValues } from 'formik';
import { FormControl } from 'formik-chakra-ui';
import { useMyProfile, useUser } from 'lib/hooks';
import { questionnements } from '@inspire/data/static/questionnements';
import { RecapCheckbox } from 'components/activites/recap-helpers';

const labels = {
  etudes: 'Coche les cases si tu penses que pour les études…',
  orientation:
    'Coche les cases si tu penses que pour ton projet d’orientation…',
  travail: 'Coches les cases si tu penses que pour ton travail…',
};

export const MesQuestionnements = ({ goNext }) => {
  const { isProf } = useUser();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick('mesQuestionnements');

  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      mesQuestionnements: myProfile?.mesQuestionnements,
    },
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(values),
  };

  return (
    <Formik {...formik}>
      {({ values }) => (
        <Form>
          <FormControl name="mesQuestionnements" mt="10">
            <FieldArray
              name="mesQuestionnements"
              render={(arrayHelpers) =>
                Object.entries(questionnements).map(([labelKey, items]) => (
                  <Box key={labelKey}>
                    <Text fontWeight="bold" textAlign="center" mt="10">
                      {labels[labelKey]}
                    </Text>
                    {Object.entries(items).map(([key, value]) => (
                      <Box key={key}>
                        <Checkbox
                          value={key}
                          mb={4}
                          isChecked={values?.mesQuestionnements?.includes(key)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              arrayHelpers.push(key);
                              return;
                            }

                            const index =
                              values?.mesQuestionnements?.indexOf(key);
                            arrayHelpers.remove(index);
                          }}
                        >
                          …{value}
                        </Checkbox>
                      </Box>
                    ))}
                  </Box>
                ))
              }
            />
          </FormControl>
          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={isProf || values?.mesQuestionnements?.length > 0}
          />
        </Form>
      )}
    </Formik>
  );
};

export const RecapMesQuestionnements = ({ profile }) => (
  <Box>
    <RecapCheckbox
      profile={profile}
      fieldName="mesQuestionnements"
      header="Tu penses que..."
      options={Object.entries(questionnements).reduce(
        (acc, [, value]) => ({
          ...acc,
          ...value,
        }),
        {}
      )}
    />
  </Box>
);
