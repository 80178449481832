import { useEffect, useState } from 'react';
import {
  Box,
  MenuItem,
  MenuList,
  Radio,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  MenuButton,
  Menu,
  Text,
  Icon,
  Button,
  Container,
  useBreakpointValue,
  CheckboxGroup,
  Link,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { PlayIcon } from '@inspire/ui/icons';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { CheckboxContainer, RadioGroupControl } from 'formik-chakra-ui';
import { CheckboxControlOptions } from '@inspire/ui/chakra/forms/Form';
import React from 'react';
import Image from 'next/image';
import { cdnURL } from '@inspire/util/cdn';

import { ChevronDownIcon } from '@inspire/ui/icons';
import { useMyProfile, useStepContext } from 'lib/hooks';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import {
  RecapMultiOptions,
  RecapUniqOption,
} from 'components/activites/recap-helpers';
import ModalTrigger from '@inspire/ui/chakra/modal/ModalTrigger';
import ProfTips from 'components/activites/ProfTips';

const continueStudiesOrProLifeLabel =
  'Après ton bac, tu préfères poursuivre avec des études supérieures ou t’insérer dans la vie professionnelle ?';

const continueStudiesOrProLifeOptions = [
  {
    value: '1',
    label: 'Je préfère continuer mes études',
  },
  {
    value: '2',
    label: 'Je préfère m’insérer professionnellement',
  },
  {
    value: '3',
    label: 'Je ne sais pas',
  },
];

const whatDoIlikeMostAfterBacLabel =
  'Qu’est-ce qui me plait le plus dans le fait de travailler directement après mon bac ? (Plusieurs choix possibles)';
const whatDoIlikeMostAfterBacOptions = [
  { label: 'Apprendre sur le terrain', value: '1' },
  {
    label: 'Etre indépendant financièrement',
    value: '2',
  },
  {
    label: 'Avoir accès à des postes à responsabilités plus rapidement',
    value: '3',
  },
  { label: 'Développer son réseau', value: '4' },
  {
    label: 'Percevoir une bonne rémunération (au bout d’un moment)',
    value: '5',
  },
  { label: 'Se lancer dans l’entrepreneuriat', value: '6' },
  {
    label: 'Avoir la possibilité de changer de carrière',
    value: '7',
  },
];

const whatDoIlikeMostContinueStudiesLabel =
  'Qu’est-ce qui me plait le plus dans le fait de poursuivre mes études après mon bac ? (Plusieurs choix possibles)';
const whatDoIlikeMostContinueStudiesOptions = [
  { label: 'Gagner en expertise', value: '8' },
  {
    label: 'Avoir une expérience à l’étranger',
    value: '9',
  },
  {
    label:
      'Prendre son temps pour développer son projet tout en acquérant des connaissances et compétences',
    value: '10',
  },
  { label: 'Développer son réseau', value: '11' },
  {
    label: 'Percevoir une bonne rémunération',
    value: '12',
  },
  {
    label:
      'Développer ses compétences via l’alternance ou le contrat d’apprentissage',
    value: '13',
  },
  {
    label: 'Avoir la possibilité de changer de carrière',
    value: '14',
  },
];

export const InsertionProOuPoursuiteDetudes = () => {
  const { goNext } = useStepContext();
  const [allowNext, setAllowNext] = useState(false);
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, { onSuccess: goNext });

  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      continueStudiesOrProLife:
        myProfile?.agroSectorActivity3?.continueStudiesOrProLife,
    },
    onSubmit: (values) => {
      setAndRefetchMyProfile({
        agroSectorActivity3: {
          ...myProfile?.agroSectorActivity3,
          continueStudiesOrProLife: values?.continueStudiesOrProLife,
        },
      });
    },
  };

  useEffect(() => {
    setAllowNext(
      myProfile?.agroSectorActivity3?.continueStudiesOrProLife ? true : false
    );
  }, []);

  return (
    <Formik {...formik}>
      {() => (
        <Form>
          <Text mb={10}>
            Dans son projet d’orientation, il est important de savoir où on met
            les pieds et d’anticiper les choix à faire. Poursuivre ses études ou
            s’insérer directement dans le monde professionnel après le bac ? Il
            n’y a pas de bon ou mauvais choix, mais il faut savoir pourquoi on
            le fait.
          </Text>
          <RadioGroupControl
            name="continueStudiesOrProLife"
            label={continueStudiesOrProLifeLabel}
            stackProps={{ direction: 'column' }}
            onChange={() => {
              setAllowNext(true);
            }}
            isRequired
          >
            {continueStudiesOrProLifeOptions.map(
              (continueStudiesOrProLifeOption) => (
                <Radio
                  key={`continueStudiesOrProLife-option-${continueStudiesOrProLifeOption.value}`}
                  value={continueStudiesOrProLifeOption.value}
                >
                  {continueStudiesOrProLifeOption.label}
                </Radio>
              )
            )}
          </RadioGroupControl>
          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={allowNext}
          />
        </Form>
      )}
    </Formik>
  );
};

export const PourquoiPoursuivreSesEtudesOuSinsererProfessionnellement = () => {
  const { goNext } = useStepContext();
  const [allowNext, setAllowNext] = useState(false);

  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, { onSuccess: goNext });

  const [question1, setQuestion1] = useState<any>(
    myProfile?.agroSectorActivity3?.whatDoIlikeMostAfterBac
  );
  const [question2, setQuestion2] = useState<any>(
    myProfile?.agroSectorActivity3?.whatDoIlikeMostContinueStudies
  );

  useEffect(() => {
    question2?.length > 0 && question1?.length > 0
      ? setAllowNext(true)
      : setAllowNext(false);
  }, [question1, question2]);

  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      whatDoIlikeMostAfterBac:
        myProfile?.agroSectorActivity3?.whatDoIlikeMostAfterBac,
      whatDoIlikeMostContinueStudies:
        myProfile?.agroSectorActivity3?.whatDoIlikeMostContinueStudies,
    },
    onSubmit: (values) => {
      setAndRefetchMyProfile({
        agroSectorActivity3: {
          ...myProfile?.agroSectorActivity3,
          whatDoIlikeMostAfterBac: values?.whatDoIlikeMostAfterBac,
          whatDoIlikeMostContinueStudies:
            values?.whatDoIlikeMostContinueStudies,
        },
      });
    },
  };

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Text as="i">
          Alors, pour t’aider dans tes réflexions, choisis parmi la liste
          ci-dessous 2 arguments pour la poursuite d’études, et 2 arguments pour
          l’insertion professionnelle après le bac ?
        </Text>
      </div>

      <Formik {...formik}>
        {() => (
          <Form>
            <CheckboxGroup
              defaultValue={
                myProfile?.agroSectorActivity3?.whatDoIlikeMostAfterBac
              }
              onChange={(values) => setQuestion1(values)}
            >
              <CheckboxContainer
                name="whatDoIlikeMostAfterBac"
                label={whatDoIlikeMostAfterBacLabel}
                stackProps={{ pl: 4 }}
                mt={10}
              >
                <CheckboxControlOptions
                  name="whatDoIlikeMostAfterBac"
                  options={whatDoIlikeMostAfterBacOptions}
                />
              </CheckboxContainer>
            </CheckboxGroup>
            <CheckboxGroup
              defaultValue={
                myProfile?.agroSectorActivity3?.whatDoIlikeMostContinueStudies
              }
              onChange={(values) => setQuestion2(values)}
            >
              <CheckboxContainer
                name="whatDoIlikeMostContinueStudies"
                label={whatDoIlikeMostContinueStudiesLabel}
                stackProps={{ pl: 4 }}
                mt={10}
              >
                <CheckboxControlOptions
                  name="whatDoIlikeMostContinueStudies"
                  options={whatDoIlikeMostContinueStudiesOptions}
                />
              </CheckboxContainer>{' '}
            </CheckboxGroup>

            <FormActiviteFooter
              isLoading={isSettingAndRefetchingMyProfile}
              allowNext={allowNext}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

const SecteurAvenirFiliere = ({
  level,
  filiere,
  isMobile = false,
}: {
  level?: any;
  filiere?: any;
  isMobile: boolean;
}) => {
  const FiliereLevelsImages = {
    production: {
      postBac: [
        {
          image: cdnURL(
            `/module-agro/Post%20bac/Production/filiere_equine.webp`
          ),
          text: `Filière équine : Éleveur équin, palefrenier, soigneur`,
          videoTitle: `Agent·e d’élevage`,
          videoUrl: `https://www.youtube.com/embed/vEgXGt-DXyE`,
        },
        {
          image: cdnURL(
            `/module-agro/Post%20bac/Production/conducteur_machine_agricole.webp`
          ),
          text: `Conducteur de machine agricole`,
        },
        {
          image: cdnURL(
            `/module-agro/Post%20bac/Production/salari%C3%A9-agricole.webp`
          ),
          text: `Salarié agricole polyvalent, vacher, porcher, berger, second d’exploitation (avec expérience), salarié horticole ou viticole, serriste, exploitant (maraicher, arboriste, pépiniériste), caviste, ouvrier de chai`,
        },
      ],
      bac23: [
        {
          image: cdnURL(
            `/module-agro/Bac%2B2ou3/Production/exploitant-eleveur.webp`
          ),
          text: `Exploitant/éleveur`,
          info: `Installation en tant qu’agriculteur ou chef d’entreprise`,
        },
        {
          image: cdnURL(
            `/module-agro/Bac%2B2ou3/Production/technicien-ou-chef-delevage.webp`
          ),
          text: `Technicien ou chef d’élevage (aquacole, viticole, horticole, arboricole), maraîchage, second d’exploitation.`,
          info: `Très bon accès à l’emploi`,
          videoTitle: `Vigneron`,
          videoUrl: `https://www.youtube.com/embed/MzGOgcgW8is`,
        },
      ],
      bac5: [
        {
          image: cdnURL(
            `/module-agro/Bac%20%2B5/Production/responsable-exploitation.webp`
          ),
          text: `Responsable d’exploitation, régisseur, exploitant (maraicher, arboriste, pépiniériste)`,
          info: `Bon ou très bon accès à l’emploi`,
        },
      ],
    },
    amenagement: {
      postBac: [
        {
          image: cdnURL(`/module-agro/Post%20bac/Amenagement/For%C3%AAt.webp`),
          text: `Forêt : exploitant forestier, élagueur, ouvrier sylviculteur, agent technique forestier`,
          videoTitle: `Élagueur·euse`,
          videoUrl: `https://www.youtube.com/embed/vuQM4PA6H00`,
        },
        {
          image: cdnURL(`/module-agro/Post%20bac/Amenagement/paysage.webp`),
          text: `Paysage : jardinier paysagiste, conducteur d’engin`,
          videoTitle: `Jardinier`,
          videoUrl: `https://www.youtube.com/embed/iTtP2ITxiVk`,
        },
        {
          image: cdnURL(
            `/module-agro/Post%20bac/Amenagement/environnement.webp`
          ),
          text: `Environnement : Salarié d’entretien de l’espace rural, garde-chasse, garde-pêche`,
        },
      ],
      bac23: [
        {
          image: cdnURL(
            `/module-agro/Bac%2B2ou3/Amenagement/technicien-specialise.webp`
          ),
          text: `Technicien spécialisé, technico-commercial en aménagement paysager ; technicien forestier Technicien assainissement eau potable/qualité des eaux ; technicien traitement des déchets`,
          info: `Très bon accès à l’emploi`,
          videoTitle: `Technicien·ne forestier·ère`,
          videoUrl: `https://www.youtube.com/embed/VZmXbxrj9k8`,
        },
        {
          image: cdnURL(
            `/module-agro/Bac%2B2ou3/Amenagement/chef-de-chantier.webp`
          ),
          text: `Chef de chantier, conducteur de travaux ; technicien bureaux d’études Animateur environnement, technicien parc nationaux, technicien de rivière`,
          info: `Accès à l’emploi moyen ou difficile`,
        },
      ],
      bac5: [
        {
          image: cdnURL(
            `/module-agro/Bac%20%2B5/Am%C3%A9nagement/chef-de-chantier.webp`
          ),
          text: `Chef de chantier, conducteur de travaux Ingénieur, chef de chantier forestier Ingénieur assainissement, eau potable, qualité des eaux et traitement des déchets`,
          info: `Bon ou très bon accès à l’emploi`,
          videoTitle: `Responsable d’exploitation hydroélectrique`,
          videoUrl: `https://www.youtube.com/embed/Lj-_QhapcRQ`,
        },
        {
          image: cdnURL(
            `/module-agro/Bac%20%2B5/Am%C3%A9nagement/responsable-bureau-etude.webp`
          ),
          text: `Responsable en bureaux d’études, architecte paysagiste Animateur environnement, parcs nationaux, chargé d’études ou de missions environnement`,
          info: `Accès à l’emploi moyen ou difficile`,
          videoTitle: `Paysagiste Concepteur`,
          videoUrl: `https://www.youtube.com/embed/GTbh1tYhI4M`,
        },
      ],
    },
    services: {
      postBac: [
        {
          image: cdnURL(`/module-agro/Post%20bac/Services/tertiaire.webp`),
          text: `Tertiaire : Secrétariat, Auxiliaire de vie, auxilliaire puéricultrice, agent en service petite enfance/maternelle`,
        },
        {
          image: cdnURL(`/module-agro/Post%20bac/Services/commerce.webp`),
          text: `Commerce : Vendeur sédentaire (agrofourniture, cave viticole, grande distribution), vendeur conseil (jardinerie, animalerie)`,
        },
        {
          image: cdnURL(`/module-agro/Post%20bac/Services/filiere_equine.webp`),
          text: `Filière équine : Moniteur/accompagnateur équestre`,
        },
        {
          image: cdnURL(`/module-agro/Post%20bac/Services/mecanicien.webp`),
          text: `Machinisme : Mécanicien, réparateur`,
        },
        {
          image: cdnURL(`/module-agro/Post%20bac/Services/silo.jpg`),
          text: `Agent de silo`,
          videoTitle: `Agent de silo`,
          videoUrl: `https://www.youtube.com/embed/MHe7URxpetg`,
        },
      ],
      bac23: [
        {
          image: cdnURL(
            `/module-agro/Bac%2B2ou3/Services/animateur-d_organisme-agricole.webp`
          ),
          text: `Animateur d’organisme agricole, agent de développement local ou territorial Agent de relation cultures/producteur Technicien inséminateur, responsable de silo Technicien d’expérimentation Formateur technique, moniteur, éducateur Technicien de machine à traire , technicien SAV, installateur de machines.`,
          info: `Bon ou très bon accès à l’emploi`,
          videoTitle: `Inséminateur`,
          videoUrl: `https://www.youtube.com/embed/jxya0JwYcDE`,
        },
      ],
      bac5: [
        {
          image: cdnURL(
            `/module-agro/Bac%20%2B5/Services/ingenieur-commercial.webp`
          ),
          text: `Ingénieur commercial en agrofournitures ou produits alimentaires, responsable de rayon en grande distribution, responsable d’équipe de ventes, acheteur, responsable grands comptes, chargé de développement Chargé de clientèle banque, assurance…`,
          info: `Bon ou très bon accès à l’emploi`,
        },
        {
          image: cdnURL(`/module-agro/Bac%20%2B5/Services/conseiller-.webp`),
          text: `Conseiller ou animateur en organisme agricole, responsable de service, conseiller d’entreprise en centre de gestion Ingénieur en expérimentation (firmes privées, instituts techniques) Formateur technique, éducateur technique, professeur dans l’enseignement agricole technique et supérieur`,
          info: `Bon ou très bon accès à l’emploi`,
          videoTitle: `Conseiller en élevage`,
          videoUrl: `https://www.youtube.com/embed/ptxV-WjqeqA`,
        },
        {
          image: cdnURL(
            `/module-agro/Bac%20%2B5/Services/directeur-marketing.webp`
          ),
          text: `Chef de produit, responsable marketing, directeur de centre de profit`,
          info: `Accès à l’emploi moyen ou difficile`,
          videoTitle: `Responsable marketing`,
          videoUrl: `https://www.youtube.com/embed/nCGztfH3MtI`,
        },
      ],
    },
    transformation: {
      postBac: [
        {
          image: cdnURL(
            `/module-agro/Post%20bac/Transformation/operateur-de-production.webp`
          ),
          text: `Opérateur de production`,
          videoTitle: `Régleur découpe volaille`,
          videoUrl: `https://www.youtube.com/embed/SRpjJlv9wxY`,
        },
        {
          image: cdnURL(
            `/module-agro/Post%20bac/Transformation/conducteur-de-ligne.webp`
          ),
          text: `Conducteur de ligne`,
        },
      ],
      bac23: [
        {
          image: cdnURL(
            `/module-agro/Bac%2B2ou3/Transformation/agent-de-maitrise.webp`
          ),
          text: `Agent de maîtrise, chef d’équipe fabrication, conducteur de machine, conducteur de ligne de fabrication ou conditionnement, technicien de chai`,
          info: `Très bon accès à l’emploi`,
        },
        {
          image: cdnURL(
            `/module-agro/Bac%2B2ou3/Transformation/controle-qualite.webp`
          ),
          text: `Technicien contrôle qualité, technicien de laboratoire, technicien recherche et développement`,
          info: `Très bon accès à l’emploi`,
        },
        {
          image: cdnURL(
            `/module-agro/Bac%2B2ou3/Transformation/maitre-de-chai.webp`
          ),
          text: `Maître de chai, caviste`,
          info: `Accès à l’emploi moyen ou difficile`,
        },
      ],
      bac5: [
        {
          image: cdnURL(
            `/module-agro/Bac%20%2B5/Transformation/agent-de-maitrise.webp`
          ),
          text: `Agent de maîtrise, responsable de production Responsable ou assistant contrôle qualité, laboratoire ou en assurance qualité/hygiène/sécurité/environnement`,
          info: `Très bon accès à l’emploi`,
          videoTitle: `Responsable développement durable`,
          videoUrl: `https://www.youtube.com/embed/YaZAt8YahBM`,
        },
        {
          image: cdnURL(
            `/module-agro/Bac%20%2B5/Transformation/maitre-de-chai.webp`
          ),
          text: `Oenologue, maitre de chai Responsable ingénieur en recherche et développement`,
          info: `Accès à l’emploi moyen ou difficile`,
          videoTitle: `Maitre de Chai`,
          videoUrl: `https://www.youtube.com/embed/16kf1MGqxhk`,
        },
      ],
    },
  };

  const itemsNumber = FiliereLevelsImages[filiere.key][level.key].length;

  return (
    <>
      {FiliereLevelsImages[filiere.key] &&
        FiliereLevelsImages[filiere.key][level.key] && (
          <SimpleGrid
            columns={isMobile ? 1 : itemsNumber > 3 ? 3 : itemsNumber}
            spacing={10}
            mt={10}
          >
            {FiliereLevelsImages[filiere.key][level.key].map(
              (filiereLevelImage: any, index: number) => (
                <Box
                  style={{ position: 'relative' }}
                  textAlign={'center'}
                  key={`image-${filiere.key}-${level.key}-${index}`}
                >
                  {filiereLevelImage.videoUrl &&
                  filiereLevelImage.videoTitle ? (
                    <ModalTrigger
                      modalProps={{ size: 'sm' }}
                      trigger={(openModal) => (
                        <Link onClick={openModal}>
                          <Box style={{ position: 'relative' }}>
                            <Icon
                              as={PlayIcon}
                              position="absolute"
                              top={0}
                              bottom={0}
                              left={0}
                              right={0}
                              zIndex={1}
                              margin="auto"
                              w={24}
                              h={24}
                              color="yellow.500"
                            />
                            <Image
                              src={filiereLevelImage.image}
                              width="500px"
                              height="400px"
                              objectFit="cover"
                            />
                          </Box>
                          <Text as="b" display={'block'} mb={10}>
                            {filiereLevelImage.text}
                          </Text>
                        </Link>
                      )}
                    >
                      {() => (
                        <>
                          <ModalHeader>
                            {filiereLevelImage.videoTitle}
                          </ModalHeader>
                          <ModalCloseButton />
                          <ModalBody ml="-2">
                            <iframe
                              width="356"
                              height="634"
                              src={filiereLevelImage.videoUrl}
                              title={filiereLevelImage.videoTitle}
                              frameBorder="0"
                              referrerPolicy="strict-origin-when-cross-origin"
                              allowFullScreen
                            ></iframe>
                          </ModalBody>
                        </>
                      )}
                    </ModalTrigger>
                  ) : (
                    <>
                      <Image
                        src={filiereLevelImage.image}
                        width="500px"
                        height="400px"
                        objectFit="cover"
                      />
                      <Text as="b" display={'block'} mb={10}>
                        {filiereLevelImage.text}
                      </Text>
                    </>
                  )}

                  {filiereLevelImage?.info && (
                    <Container
                      bg="#EDF2F7"
                      p={1}
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                      }}
                    >
                      <Text as="i">{filiereLevelImage.info}</Text>
                    </Container>
                  )}
                </Box>
              )
            )}
          </SimpleGrid>
        )}
    </>
  );
};

export const VerifierLesSecteursAvenir = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const filieres: any = [
    {
      key: 'production',
      label: 'PRODUCTION',
      percentJob: 36,
    },
    {
      key: 'amenagement',
      label: 'AMÉNAGEMENT',
      percentJob: 19,
    },
    {
      key: 'services',
      label: 'SERVICES',
      percentJob: 41,
    },
    {
      key: 'transformation',
      label: 'TRANSFORMATION',
      percentJob: 4,
    },
  ];

  const levels: any = [
    {
      key: 'postBac',
      label: 'Bac',
    },
    {
      key: 'bac23',
      label: 'Bac +2 ou 3',
    },
    {
      key: 'bac5',
      label: 'Bac +5',
    },
  ];

  const [level, setLevel] = useState<{ key: string; label: string }>(levels[0]);
  const [filiere, setFiliere] = useState<{
    key: string;
    label: string;
    percentJob: number;
  }>(filieres[0]);

  const textInfofiliere = (
    <Text backgroundColor="#FFF6B8" m={4} p={2} align={'center'}>
      La filière{' '}
      {filiere.label === 'AMÉNAGEMENT'
        ? 'd’aménagement'
        : `de ${filiere.label.toLowerCase()}`}{' '}
      représente
      {isMobile && <br />}
      <b> {filiere.percentJob}% des emplois.</b>
    </Text>
  );

  return (
    <>
      <ProfTips mb={5}>
        <Text>
          Pour faire découvrir aux élèves le quotidien de nombreux métiers,
          rendez-vous sur{' '}
          <Link
            href="https://wilbi-app.com/?utm_source=inspire"
            target="_blank"
          >
            Wilbi
          </Link>
          , qui propose des centaines de métiers en vidéo gratuitement.
        </Text>
      </ProfTips>
      <Text mb={5}>
        Que tu prennes la décision de t’insérer directement dans la vie active
        ou de poursuivre tes études après le bac, il est important de savoir
        pourquoi tu le fais pour que ce soit un réel choix.
      </Text>
      <Text mb={5}>
        Tu as le droit de changer d’avis, et des formations courtes existent
        pour pouvoir monter en compétences, s’adapter aux évolutions du monde du
        travail et s’assurer de trouver un emploi.
      </Text>
      <Text mb={5}>
        Il est important aussi de vérifier si la ou les filières vers lesquelles
        tu te projettes ont des débouchés intéressants et ouverts.
      </Text>
      <Text as="b">
        Consultes les différents débouchés en fonction des filières et du niveau
        d’études. Tu peux cliquer sur les vidéos pour voir des exemples de
        métiers.
      </Text>
      {!isMobile && (
        <Tabs
          variant="soft-rounded"
          colorScheme="gray"
          isFitted
          mt={10}
          defaultIndex={0}
          onChange={(index) => {
            setFiliere(filieres[index]);
          }}
          isLazy
        >
          <TabList>
            {filieres.map((filiere: any) => (
              <Tab key={`tablist-${filiere.label}`}>{filiere.label}</Tab>
            ))}
          </TabList>
          {textInfofiliere}
          <div style={{ textAlign: 'center' }}>
            <span style={{ fontWeight: 'bold' }}>Niveau : </span>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<Icon as={ChevronDownIcon} boxSize={5} />}
                colorScheme="gray"
              >
                <Box>{level.label}</Box>
              </MenuButton>
              <MenuList>
                {levels.map((_level: any) => (
                  <MenuItem
                    key={`menuitem-${_level.key}`}
                    value={_level.key}
                    onClick={() => setLevel(_level)}
                  >
                    {_level.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </div>
          <TabPanels mt="4">
            <TabPanel>
              <SecteurAvenirFiliere
                filiere={filieres[0]}
                level={level}
                isMobile={false}
              />
            </TabPanel>
            {/********************************************************************** */}
            <TabPanel>
              <SecteurAvenirFiliere
                filiere={filieres[1]}
                level={level}
                isMobile={false}
              />
            </TabPanel>
            {/********************************************************************** */}
            <TabPanel>
              <SecteurAvenirFiliere
                filiere={filieres[2]}
                level={level}
                isMobile={false}
              />
            </TabPanel>
            {/********************************************************************** */}
            <TabPanel>
              <SecteurAvenirFiliere
                filiere={filieres[3]}
                level={level}
                isMobile={false}
              />
            </TabPanel>
            {/********************************************************************** */}
          </TabPanels>
        </Tabs>
      )}
      {/************************************************************************************** */}
      {isMobile && (
        <>
          <div style={{ textAlign: 'center' }}>
            <span style={{ fontWeight: 'bold' }}>Filière : </span>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<Icon as={ChevronDownIcon} boxSize={5} />}
                colorScheme="gray"
              >
                <Box>{filiere.label}</Box>
              </MenuButton>
              <MenuList>
                {filieres.map((_filiere: any) => (
                  <MenuItem
                    key={`menuitem-${_filiere.key}`}
                    value={_filiere.key}
                    onClick={() => setFiliere(_filiere)}
                  >
                    {_filiere.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </div>
          {textInfofiliere}
          <div style={{ textAlign: 'center' }}>
            <span style={{ fontWeight: 'bold' }}>Niveau : </span>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<Icon as={ChevronDownIcon} boxSize={5} />}
                colorScheme="gray"
              >
                <Box>{level.label}</Box>
              </MenuButton>
              <MenuList>
                {levels.map((_level: any) => (
                  <MenuItem
                    key={`menuitem-${_level.key}`}
                    value={_level.key}
                    onClick={() => setLevel(_level)}
                  >
                    {_level.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </div>
          <SecteurAvenirFiliere
            filiere={filiere}
            level={level}
            isMobile={true}
          />
        </>
      )}
    </>
  );
};

export const RecapJeMeProjetteEtJeMinforme = ({ profile }) => {
  return (
    <>
      <RecapUniqOption
        profile={profile}
        fieldName="agroSectorActivity3.continueStudiesOrProLife"
        label={continueStudiesOrProLifeLabel}
        options={continueStudiesOrProLifeOptions}
      />
      <RecapMultiOptions
        profile={profile}
        fieldName="agroSectorActivity3.whatDoIlikeMostAfterBac"
        label={whatDoIlikeMostAfterBacLabel}
        options={whatDoIlikeMostAfterBacOptions}
      />
      <RecapMultiOptions
        profile={profile}
        fieldName="agroSectorActivity3.whatDoIlikeMostContinueStudies"
        label={whatDoIlikeMostContinueStudiesLabel}
        options={whatDoIlikeMostContinueStudiesOptions}
      />
    </>
  );
};
