import { Box, Radio, SimpleGrid, Text } from '@chakra-ui/react';
import {
  initFormikValuesFromSchema,
  validateSchema,
} from '@inspire/data/helpers/schemas';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import { valeursOptions } from '@inspire/data/static/valeurs';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import ProfTips from 'components/activites/ProfTips';
import { RecapRadio, RecapTextArea } from 'components/activites/recap-helpers';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { RadioGroupControl, TextareaControl } from 'formik-chakra-ui';
import { useMyProfile, useUser } from 'lib/hooks';

export const MesForcesEtAspirations = ({ goNext }) => {
  const { isProf } = useUser();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(
    'aspirationsMetiersQuiInteressaient',
    'aspirationsMetiersQuiInteressent',
    'aspirationsCentresInterets',
    'aspirationsCeEnQuoiJeSuisFort',
    'aspirationsOntEvolue',
    'aspirationsPointsCommunsMetiersValeurs',
    'aspirationsForcesUtiles',
    'aspirationsPointsCommunsMetiersCentresInterets'
  ).requireFields();
  const formik: FormikConfig<FormikValues> = {
    initialValues: initFormikValuesFromSchema(schema, myProfile),
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(schema.clean(values)),
  };

  if (isProf) {
    myProfile.motsclesValeur = ['ecologie', 'justice', 'solidarite'];
  }

  return (
    <Formik {...formik}>
      <Form>
        <Text textAlign="center">
          Remplis le tableau suivant, puis réponds aux questions en dessous.
          <span style={{ color: 'red' }}>*</span>
        </Text>

        <ProfTips mb={3} mt={10}>
          <Text>
            Ci-dessous, des exemples de valeurs que pourraient sélectionner les
            lycéens.
          </Text>
        </ProfTips>

        <Box bg="blue.100" px={6} py={2} borderRadius={4} mb={8}>
          <Text fontWeight="bold">Mes valeurs :</Text>
          <ul>
            {myProfile?.motsclesValeur?.map((value) => (
              <li key={value}>{valeursOptions[value]?.label}</li>
            ))}
          </ul>
        </Box>
        <SimpleGrid columns={[1, null, 2]} spacing={4}>
          <Box>
            <TextareaControl
              label="Métiers ou secteur qui m’intéressaient quand j’étais enfant ? Pourquoi ?"
              name="aspirationsMetiersQuiInteressaient"
              isRequired={true}
              as="fieldset"
              bg="yellow.500"
              textareaProps={{ bg: 'white' }}
              borderRadius={'10px'}
              p={4}
            />
          </Box>
          <Box>
            <TextareaControl
              label="Métiers ou secteurs qui m’intéressent aujourd’hui, pourquoi ?"
              name="aspirationsMetiersQuiInteressent"
              isRequired={true}
              as="fieldset"
              bg="yellow.500"
              textareaProps={{ bg: 'white' }}
              borderRadius={'10px'}
              p={4}
            />
          </Box>
          <Box>
            <TextareaControl
              label="Mes centres d’intérêts, pourquoi ?"
              name="aspirationsCentresInterets"
              isRequired={true}
              as="fieldset"
              bg="yellow.500"
              textareaProps={{ bg: 'white' }}
              borderRadius={'10px'}
              p={4}
            />
          </Box>
          <Box>
            <TextareaControl
              label="Ce en quoi je suis fort (matières, compétences)"
              name="aspirationsCeEnQuoiJeSuisFort"
              isRequired={true}
              as="fieldset"
              bg="yellow.500"
              textareaProps={{ bg: 'white' }}
              borderRadius={'10px'}
              p={4}
            />
          </Box>
        </SimpleGrid>

        <RadioGroupControl
          name="aspirationsOntEvolue"
          label="Tes idées de métier ou de secteur ont-elles évolué depuis ton enfance ?"
          stackProps={{ direction: 'column' }}
          isRequired={true}
          mt="10"
        >
          <Radio value="oui">Oui</Radio>
          <Radio value="non">Non</Radio>
        </RadioGroupControl>

        <TextareaControl
          label="Quels sont les points communs entre tes idées de métiers et tes valeurs ?"
          name="aspirationsPointsCommunsMetiersValeurs"
          isRequired={true}
          mt="10"
        />

        <TextareaControl
          label="Est-ce que, selon toi, tes forces sont utiles pour tes idées de métier ou de secteur professionnel ?"
          name="aspirationsForcesUtiles"
          isRequired={true}
          mt="10"
        />

        <TextareaControl
          label="Quels sont les points communs entre tes idées de métiers et tes centres d’intérêts ?"
          name="aspirationsPointsCommunsMetiersCentresInterets"
          isRequired={true}
          mt="10"
        />

        <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
      </Form>
    </Formik>
  );
};

export const RecapMesForcesEtAspirations = ({ profile }) => (
  <Box>
    <RecapTextArea
      profile={profile}
      fieldName="aspirationsMetiersQuiInteressaient"
      header="Métiers ou secteur qui m’intéressaient quand j’étais enfant ? Pourquoi ?"
    />
    <RecapTextArea
      profile={profile}
      fieldName="aspirationsMetiersQuiInteressent"
      header="Métiers ou secteurs qui m’intéressent aujourd’hui, pourquoi ?"
    />
    <RecapTextArea
      profile={profile}
      fieldName="aspirationsCentresInterets"
      header="Mes centres d’intérêts, pourquoi ?"
    />
    <RecapTextArea
      profile={profile}
      fieldName="aspirationsCeEnQuoiJeSuisFort"
      header="Ce en quoi je suis fort (matières, compétences)"
    />

    <RecapRadio
      profile={profile}
      fieldName="aspirationsOntEvolue"
      header="Tes idées de métier ou de secteur ont-elles évolué depuis ton enfance ?"
      options={{ oui: 'Oui', non: 'Non' }}
    />

    <RecapTextArea
      profile={profile}
      header="Quels sont les points communs entre tes idées de métiers et tes valeurs ?"
      fieldName="aspirationsPointsCommunsMetiersValeurs"
    />

    <RecapTextArea
      profile={profile}
      header="Est-ce que, selon toi, tes forces sont utiles pour tes idées de métier ou de secteur professionnel ?"
      fieldName="aspirationsForcesUtiles"
    />

    <RecapTextArea
      profile={profile}
      header="Quels sont les points communs entre tes idées de métiers et tes centres d’intérêts ?"
      fieldName="aspirationsPointsCommunsMetiersCentresInterets"
    />
  </Box>
);
