import { take } from 'lodash';
import {
  Box,
  Icon,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useMyProfile, usePistes, useUser } from 'lib/hooks';
import { dureeOptions, experienceProOptions } from './ComparerFormations';
import { CheckboxOffIcon, CheckboxOnIcon } from '@inspire/ui/icons';

const randomKey = (object) => {
  const keys = Object.keys(object);
  return keys[(keys.length * Math.random()) << 0];
};

const randomDuree = randomKey(dureeOptions);
const randomExperiencePro = randomKey(experienceProOptions);

const getFormationComparaison = (profile, pisteId) =>
  profile?.formationComparaisons?.find(
    (element) => element.pisteId === pisteId
  );

export const TableauComparaisonFormations = ({ goNext }) => {
  const { isProf } = useUser();
  const { myProfile } = useMyProfile(null, {
    onSuccess: goNext,
  });

  const { pistes } = usePistes();

  let pistesReco = [];

  if (pistes) {
    const pisteIds = myProfile?.formationComparaisons?.map(
      (value) => value.pisteId
    );

    pistesReco = isProf
      ? take(pistes, 3)
      : pistes.filter((piste) => pisteIds.includes(piste._id));
  }

  return (
    <>
      <Text textAlign="center">
        Consulte le tableau pour comparer tes formations recommandées :
      </Text>

      <TableContainer whiteSpace={'pre-wrap'}>
        <Table mt={6} border="1px solid" borderColor={'gray.100'} size="sm">
          <Thead>
            <Tr bg={'yellow.500'}>
              <Th>Formation</Th>
              <Th>Durée</Th>
              <Th>Coût</Th>
              <Th>Expériences pro</Th>
              <Th>Débouchés qui m’intéressent</Th>
            </Tr>
          </Thead>

          <Tbody>
            {pistesReco?.map((piste) => {
              const formationComparaison =
                myProfile?.formationComparaisons?.find(
                  (element) => element.pisteId === piste._id
                );

              const duree = isProf ? randomDuree : formationComparaison?.duree;
              const experiencePro = isProf
                ? randomExperiencePro
                : formationComparaison?.experiencePro;
              const cout = isProf
                ? 'Lorem ipsum...'
                : formationComparaison?.cout;
              const debouches = isProf
                ? 'Lorem ipsum...'
                : formationComparaison?.debouches;

              return (
                <Tr key={`row_${piste._id}`}>
                  <Th>{piste.name}</Th>
                  <Td whiteSpace={'nowrap'}>{dureeOptions[duree]}</Td>
                  <Td>{cout}</Td>
                  <Td whiteSpace={'nowrap'}>
                    {Object.entries(experienceProOptions).map(
                      ([key, label]) => (
                        <Box
                          key={`formationComparaisons_${piste._id}_experiencePro_${key}`}
                        >
                          <Icon
                            as={
                              experiencePro.includes(key)
                                ? CheckboxOnIcon
                                : CheckboxOffIcon
                            }
                            boxSize={6}
                            verticalAlign="middle"
                          />{' '}
                          {label}
                        </Box>
                      )
                    )}
                  </Td>
                  <Td>{debouches}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export const RecapTableauComparaisonFormations = ({ profile }) => {
  const { pistes } = usePistes();

  let pistesReco = [];

  if (pistes) {
    const pisteIds = profile?.formationComparaisons?.map(
      (value) => value.pisteId
    );

    pistesReco = pistes.filter((piste) => pisteIds.includes(piste._id));
  }

  const pistesSorted = [];
  pistesReco.map((piste, index) => {
    const formationComparaison = getFormationComparaison(profile, piste._id);
    pistesSorted.push({
      id: piste._id,
      label: piste.name,
      order:
        formationComparaison?.order >= 0 ? formationComparaison?.order : index,
    });
  });
  pistesSorted.sort((a, b) => a.order - b.order);

  return (
    <>
      <Text fontWeight={'bold'}>
        Tableau de comparaison des pistes recommandées :
      </Text>
      <TableContainer whiteSpace={'pre-wrap'} mb="10">
        <Table border="1px solid" borderColor={'gray.100'} size="sm">
          <Thead>
            <Tr bg={'yellow.500'}>
              <Th>Formation</Th>
              <Th>Durée</Th>
              <Th>Coût</Th>
              <Th>Expériences pro</Th>
              <Th>Débouchés qui m’intéressent</Th>
            </Tr>
          </Thead>

          <Tbody>
            {pistesReco?.map((piste) => {
              const formationComparaison = getFormationComparaison(
                profile,
                piste._id
              );

              const duree = formationComparaison?.duree;
              const experiencePro = formationComparaison?.experiencePro;
              const cout = formationComparaison?.cout;
              const debouches = formationComparaison?.debouches;

              return (
                <Tr key={`row_${piste._id}`}>
                  <Th>{piste.name}</Th>
                  <Td whiteSpace={'nowrap'}>{dureeOptions[duree]}</Td>
                  <Td>{cout}</Td>
                  <Td whiteSpace={'nowrap'}>
                    {Object.entries(experienceProOptions).map(
                      ([key, label]) => (
                        <Box
                          key={`formationComparaisons_${piste._id}_experiencePro_${key}`}
                        >
                          <Icon
                            as={
                              experiencePro.includes(key)
                                ? CheckboxOnIcon
                                : CheckboxOffIcon
                            }
                            boxSize={6}
                            verticalAlign="middle"
                          />{' '}
                          {label}
                        </Box>
                      )
                    )}
                  </Td>
                  <Td>{debouches}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>

      <Text fontWeight={'bold'}>
        Qu’est-ce qui te correspond dans ces pistes proposées ?
      </Text>

      <SimpleGrid columns={{ sm: 1, lg: 3 }} spacing="30px" mb="10">
        {pistesReco.map((piste) => {
          const formationComparaison = getFormationComparaison(
            profile,
            piste._id
          );

          return (
            <Box
              key={`correspond_${piste._id}`}
              display="flex"
              flexDirection="column"
              alignItems="stretch"
            >
              <Box
                textAlign="center"
                fontWeight="bold"
                minHeight="50px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text>{piste.name}</Text>
              </Box>
              <Box>{formationComparaison?.correspond}</Box>
            </Box>
          );
        })}
      </SimpleGrid>

      <Text fontWeight={'bold'}>
        Qu’est-ce qui te correspond moins dans ces pistes ?
      </Text>

      <SimpleGrid columns={{ sm: 1, lg: 3 }} spacing="30px" mb="10">
        {pistesReco.map((piste) => {
          const formationComparaison = getFormationComparaison(
            profile,
            piste._id
          );

          return (
            <Box
              key={`correspondpas_${piste._id}`}
              display="flex"
              flexDirection="column"
              alignItems="stretch"
            >
              <Box
                textAlign="center"
                fontWeight="bold"
                minHeight="50px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text>{piste.name}</Text>
              </Box>
              <Box>{formationComparaison?.correspondpas}</Box>
            </Box>
          );
        })}
      </SimpleGrid>

      <Text fontWeight={'bold'}>
        Place dans ton ordre de préférence les formations qui t’intéressent en
        lien avec ton projet.
      </Text>
      <ol>
        {pistesSorted.map((piste) => (
          <li key={`pistesSorted_${piste.id}`}>{piste.label}</li>
        ))}
      </ol>
    </>
  );
};
