import { Box, Heading, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { get } from 'lodash';

export const RecapTextArea = ({ profile, fieldName, header }) =>
  profile && profile[fieldName] ? (
    <Box>
      <Heading size="sm" as="h3" fontWeight={'bold'}>
        {header}
      </Heading>
      <Text>{profile[fieldName]}</Text>
    </Box>
  ) : null;

export const RecapRadio = ({ profile, fieldName, header, options }) => {
  return profile && profile[fieldName] ? (
    <Box>
      <Heading size="sm" as="h3" fontWeight={'bold'}>
        {header}
      </Heading>
      <UnorderedList>
        <ListItem>{options[profile[fieldName]]}</ListItem>
      </UnorderedList>
    </Box>
  ) : null;
};

export const RecapCheckbox = ({
  profile,
  fieldName,
  header,
  options,
  inverseValues = false,
}) => {
  const values = inverseValues
    ? Object.keys(options).filter((k) => !profile[fieldName]?.includes(k))
    : profile[fieldName];
  return profile && profile[fieldName] && profile[fieldName].length > 0 ? (
    <Box>
      <Heading size="sm" as="h3" fontWeight={'bold'}>
        {header}
      </Heading>
      <UnorderedList>
        {values?.map((q, i) => (
          <ListItem key={i}>{options[q]}</ListItem>
        ))}
      </UnorderedList>
    </Box>
  ) : null;
};

export const RecapSlider = ({
  profile,
  fieldName,
  header,
  options,
  sliderOptions,
}) => {
  return profile && profile[fieldName] ? (
    <Box>
      <Heading size="sm" as="h3">
        {header}
      </Heading>
      <Text m={0}>
        {sliderOptions.min} = {sliderOptions.lowLabel}
      </Text>
      <Text m={0}>
        {sliderOptions.max} = {sliderOptions.highLabel}
      </Text>
      <UnorderedList>
        {options?.map((q, i) => {
          const val = profile[fieldName]?.find((f) => f.key === q.key)?.value;
          return (
            <ListItem key={i}>
              {q.label}: {val}
              {val &&
                sliderOptions?.valueLabels &&
                ` (${sliderOptions?.valueLabels[val]})`}
            </ListItem>
          );
        })}
      </UnorderedList>
    </Box>
  ) : null;
};

export const RecapOptionsFields = ({
  profile,
  fieldName,
  header,
  valueToLabelMethod,
}) => {
  return profile && profile[fieldName] && profile[fieldName].length > 0 ? (
    <Box>
      <Heading size="sm" as="h3">
        {header}
      </Heading>
      <UnorderedList>
        {profile[fieldName]?.map((q, i) => (
          <ListItem key={i}>{valueToLabelMethod(q)}</ListItem>
        ))}
      </UnorderedList>
    </Box>
  ) : null;
};

export const YesNoOptions = {
  yes: 'Oui',
  partly: 'Oui, en partie',
  no: 'Non',
  notReally: 'Non, pas vraiment',
  unknown: 'Je ne sais pas',
};

export const RecapList = ({ profile, fieldName, label }) => {
  return (
    <>
      <Text as="b">{label}</Text>
      <UnorderedList>
        {get(profile, fieldName)?.map((value: string, index: number) => (
          <ListItem key={`${fieldName}-${index}`}>{value}</ListItem>
        ))}
      </UnorderedList>
    </>
  );
};

export const RecapUniqOption = ({ profile, fieldName, label, options }) => {
  const value = get(profile, fieldName);
  return (
    <>
      <Text as="b">{label}</Text>
      <Text ml={4}>
        {value &&
          options.find(
            (option: { label: string; value: string }) => option.value === value
          )?.label}
      </Text>
    </>
  );
};

export const RecapMultiOptions = ({ profile, fieldName, label, options }) => {
  const values: string[] = get(profile, fieldName);
  return (
    <>
      <Text as="b">{label}</Text>
      <UnorderedList>
        {options
          .filter((option: { label: string; value: string }) =>
            values.includes(option.value)
          )
          .map((option: { label: string; value: string }, index: number) => (
            <ListItem key={`${fieldName}-${index}`}>{option.label}</ListItem>
          ))}
      </UnorderedList>
    </>
  );
};
