import { filter, map, includes, take, extend, sortBy, uniqBy } from "lodash";
export function orderPistesByScore(algoResults, pistes, dontFilter) {
    if (!(algoResults === null || algoResults === void 0 ? void 0 : algoResults.length)) {
        return pistes;
    }
    // remove duplicate pisteIds in algoResults
    var uniqAlgoResults = uniqBy(algoResults, function(r) {
        return r.pisteId;
    });
    // extend all pistes with a score
    var pistesWithScore = map(pistes, function(p) {
        var piste = uniqAlgoResults.find(function(r) {
            return r.pisteId === p.objectID || r.pisteId === p._id;
        });
        return extend(p, {
            score: (piste === null || piste === void 0 ? void 0 : piste.score) || 0
        });
    });
    var filteredPistes = pistesWithScore.filter(function(p) {
        return p.score > 0;
    });
    // If user is GT, we show all pistes, if not, we show the pistes with a score > 0
    return sortBy(dontFilter ? pistesWithScore : filteredPistes, function(r) {
        return -r.score;
    });
}
export function filterPistesReco(algoResults) {
    // remove duplicate pisteIds in algoResults
    var uniqAlgoResults = uniqBy(algoResults, function(r) {
        return r.pisteId;
    });
    var LIMIT = 20;
    var aboveLimit = filter(uniqAlgoResults, function(r) {
        return r.score >= LIMIT;
    });
    return aboveLimit.length > 4 ? aboveLimit : take(uniqAlgoResults, 4);
}
export function getPistesRecoIds(algoResults) {
    var reco = filterPistesReco(algoResults);
    return map(reco, "pisteId");
}
export function getPistesReco(algoResults, pistes) {
    var recoIds = getPistesRecoIds(algoResults);
    return filter(orderPistesByScore(algoResults, pistes), function(p) {
        return includes(recoIds, p.objectID) || includes(recoIds, p._id);
    });
}
export function getPistesNotReco(algoResults, pistes) {
    var recoIds = getPistesRecoIds(algoResults);
    return filter(orderPistesByScore(algoResults, pistes), function(p) {
        return !includes(recoIds, p.objectID);
    });
}
