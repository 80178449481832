import {
  Box,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Heading,
  Text,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

import ClickableImage from 'components/general/ClickableImage';

import InfographieEtudesSupProSrc from '../../../public/img/infographies/etudes-superieurs.jpg';
import InfographiesCalendrierParcoursupSrc from '../../../public/img/infographies/calendrier-parcoursup.png';
import InfographiesReponsesFormationsParcoursupSrc from '../../../public/img/infographies/rep-des-formations-sur-parcoursup.png';
import InfographieRepondreFormationsSelectivesSrc from '../../../public/img/infographies/rep-aux-propositions-selectives.png';
import InfographieRepondreFormationsNonSelectivesSrc from '../../../public/img/infographies/rep-aux-propositions-non-selectives.png';
import InfographieReglesOrPfmSrc from '../../../public/img/infographies/regles-or-PFM.png';

import { FileTextIcon, FileImageIcon } from '@inspire/ui/icons';
import { DefinitionBox } from 'components/general/DefinitionBox';
import Link from 'components/general/Link';
import Euros from 'components/general/Euros';
import {
  GTM_CATEGORY_KIT_DE_SURVIE,
  GTM_ACTION_VOIR_RUBRIQUE,
  pushEvent,
} from 'lib/gtm';

export const AidesEtudiantes = () => (
  <>
    <Heading size="sm">Aides étudiantes</Heading>

    <Accordion allowMultiple>
      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Bourse sur critères sociaux du CROUS'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Bourse sur critères sociaux du CROUS
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Bourse sur critères sociaux du CROUS">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              La bourse du CROUS est attribuée en fonction des ressources et des
              charges du foyer fiscal de l’étudiant, du nombre d'enfants à
              charge et de l'éloignement entre le domicile et le lieu d'études.
            </Text>
            <Heading size="xs">✓ Quel montant ?</Heading>
            <Text>
              De l’échelon 0bis à 7, entre 145€ et 633€ par mois pendant 10 mois
              de septembre à juin. Faire une simulation sur :{' '}
              <Link href="https://simulateur.lescrous.fr" isExternal>
                https://simulateur.lescrous.fr
              </Link>
            </Text>
            <Heading size="xs">✓ Où faire sa demande ?</Heading>{' '}
            <Text>
              Remplir son Dossier Social Étudiant (DSE) :
              <Link href="https://www.messervices.etudiant.gouv.fr" isExternal>
                www.messervices.etudiant.gouv.fr
              </Link>
            </Text>
            <Heading size="xs">✓ Pour plus d’informations :</Heading>
            <Text>
              <Link
                href="https://inspire-orientation.org/blog/faire-sa-demande-de-bourse-comment-ca-marche"
                isExternal
              >
                Le CROUS : Comment ça marche ?
              </Link>
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Aide au mérite'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Aide au mérite
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Aide au mérite">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              L’aide au mérite est un complément à la bourse sur critères
              sociaux pour les élèves boursiers ayant une mention Très Bien au
              Bac (au moins 16/20) qui souhaitent continuer leurs études après
              le bac.
            </Text>
            <Heading size="xs">✓ Quel montant ?</Heading>
            <Text>
              <Euros>900</Euros> par an versée en 9 mensualités de 100 € (sur 3
              ans maximum).
            </Text>
            <Heading size="xs">✓ Où faire sa demande ?</Heading>
            <Text>
              Elle est versée automatiquement en complément de la bourse sur
              critères sociaux, dont la demande se fait via le Dossier Social
              Étudiant (DSE).
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Bourse mairie, département, région'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Bourse mairie, département, région
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Bourse mairie, département, région">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              Il existe des aides financières proposées par les villes, les
              départements ou les régions. Il peut s’agir de bourses d’études,
              d’aide à la mobilité, d’aide à l’installation, de bourse au
              mérite, d’un revenu minimum étudiant (RME), etc.
            </Text>
            <Heading size="xs">✓ Pour plus d’informations :</Heading>
            <Text>
              <Link
                href="https://www.etudiant.gouv.fr/pid37652/aides-regionales.html"
                isExternal
              >
                https://www.etudiant.gouv.fr/pid37652/aides-regionales.html
              </Link>
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Allocation spécifique annuelle pour étudiant en difficulté'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Allocation spécifique annuelle pour étudiant en difficulté
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Allocation spécifique annuelle pour étudiant en difficulté">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              Aide financière qui peut être versée aux étudiants qui rencontrent
              des difficultés financières durables et qui ne bénéficient pas de
              la bourse d'enseignement sur critères sociaux.
            </Text>

            <Heading size="xs">✓ Quel montant ?</Heading>
            <Text>
              Le montant de l'aide correspond à un des échelons 0 bis à 7 de la
              bourse sur critères sociaux, soit entre <Euros>1 454</Euros> et{' '}
              <Euros>6 335</Euros> par an. L'aide d'urgence annuelle donne droit
              à l'exonération des frais d'inscription à l'université.
            </Text>

            <Heading size="xs">✓ Où faire sa demande ?</Heading>
            <Text>
              Contacter le service social du Crous. La demande d'aide d'urgence
              est examinée par une commission et présentée de façon anonyme. Si
              nécessaire, un entretien préalable peut avoir lieu avec un(e)
              assistant(e) social(e) du Crous.
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Aide spécifique ponctuelle pour étudiant en difficulté'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Aide spécifique ponctuelle pour étudiant en difficulté
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Aide spécifique ponctuelle pour étudiant en difficulté">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              Aide financière personnalisée pour les étudiants qui rencontrent
              passagèrement de graves difficultés financières. Elle est
              cumulable avec la bourse sur critères sociaux.
            </Text>

            <Heading size="xs">✓ Quel montant ?</Heading>
            <Text>
              Son montant maximum est de <Euros>3 071</Euros>, versés en une
              seule fois par le Crous. Si plusieurs aides ponctuelles sont
              accordées au cours de la même année universitaire, leur montant
              cumulé ne peut pas dépasser <Euros>6 142</Euros>.
            </Text>

            <Heading size="xs">✓ Où faire sa demande ?</Heading>
            <Text>
              Contacter le service social du Crous. La demande d'aide d'urgence
              est examinée par une commission et présentée de façon anonyme. Si
              nécessaire, un entretien préalable peut avoir lieu avec un(e)
              assistant(e) social(e) du Crous.
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Aides aux étudiants en situation de handicap'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Aides aux étudiants en situation de handicap
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Aides aux étudiants en situation de handicap">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>

            <Text>
              En plus des aides classiques, les étudiants en situation de
              handicap peuvent percevoir des aides complémentaires :
            </Text>

            <Text>
              <strong>La prestation de compensation du handicap</strong> versée
              par le département. La demande se fait auprès de la MDPH.
            </Text>

            <Text>
              <strong>Les aides du CROUS :</strong>
              <br />
              - Bourse d’études
              <br />
              - Dispositif spécifique d’accompagnement
              <br />
              - Aide au transport
              <br />
              - Procédure spécifique d’accès au logement
              <br />
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Revenu minimum étudiant'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Revenu minimum étudiant
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Revenu minimum étudiant">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              Pour les étudiants en situation de précarité, certaines communes
              mettent en place une aide financière : le revenu minimum étudiant
              (RME). Les conditions d’attribution sont fixées par chaque ville.
            </Text>
            <Heading size="xs">✓ Où faire sa demande ?</Heading>
            <Text>
              Les demandes de RME doivent être adressées à la mairie ou au
              centre communal d’action sociale (CCAS).
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  </>
);

export const PretsEtudiants = () => (
  <>
    <Heading size="sm">Prêts étudiants</Heading>

    <Accordion allowMultiple>
      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Prêt étudiant / prêt garanti par l’État'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Prêt étudiant / prêt garanti par l’État
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Prêt étudiant / prêt garanti par l’État">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              Il s’agit d’un prêt sans caution personnelle, ni conditions de
              ressources, accessible aux étudiants (majeurs ou mineurs
              anticipés) de moins de 28 ans, français ou ressortissants de
              l’Union européenne, boursiers ou non. Le prêt doit être remboursé
              avec les intérêts, mais le remboursement peut être différé à la
              fin des études. En cas de défaillance de l’étudiant, 70% de la
              garantie seront pris en charge par l’État.
            </Text>
            <Heading size="xs">✓ Quel montant ?</Heading>
            <Text>
              <Euros>20 000</Euros> maximum.
            </Text>
            <Heading size="xs">✓ Où faire sa demande ? </Heading>
            <Text>
              La demande se fait auprès des banques partenaires de l'opération.
              Il faut demander l'attestation de pré-éligibilité sur la
              plateforme digitale dédiée :{' '}
              <Link href="https://tokens.bpifrance.fr/" isExternal>
                https://tokens.bpifrance.fr/
              </Link>{' '}
              et la transmettre à la banque.
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Prêts bancaires'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Prêts bancaires
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Prêts bancaires">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              Les banques proposent des emprunts aux majeurs à taux avantageux
              (entre 0 et 1,5%). Le remboursement du crédit commence après les
              études.
            </Text>
            <Heading size="xs">✓ Quel montant ?</Heading>
            <Text>
              Entre <Euros>1 000</Euros> et <Euros>120 000</Euros> selon les
              dépenses et les accords avec la banque.
            </Text>
            <Heading size="xs">✓ Pour plus d’informations :</Heading>
            <Text>
              <Link
                href="https://www.letudiant.fr/lifestyle/aides-financieres/pret-etudiant-cinq-questions-a-se-poser-avant-de-se-lancer.html"
                isExternal
              >
                https://www.letudiant.fr/lifestyle/aides-financieres/pret-etudiant-cinq-questions-a-se-poser-avant-de-se-lancer.html
              </Link>
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  </>
);

export const TravaillerPendantSesEtudes = () => (
  <>
    <Heading size="sm">Travailler pendant ses études</Heading>

    <Accordion allowMultiple>
      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Job étudiant'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Job étudiant
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Job étudiant">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              Pour avoir un complément de revenus ou financer ses études, il est
              possible de travailler à côté. Le plus souvent il s’agit de
              contrats horaires (par exemple 10h par semaine).
            </Text>

            <Heading size="xs">✓ Quel montant ?</Heading>
            <Text>
              Les jobs étudiants sont souvent payés au SMIC, soit un peu plus de{' '}
              <Euros>9</Euros> de l’heure.
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Le statut d’étudiant salarié'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Le statut d’étudiant salarié
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Le statut d’étudiant salarié">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              Ce statut peut être demandé auprès de son établissement pour les
              étudiants qui travaillent à côté (environ 15 heures par semaine)
              afin d’avoir des aménagements (par exemple : aménagement d’emploi
              du temps, contrôle final au lieu d'un contrôle continu).
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Service civique'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Service civique
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Service civique">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              C’est un dispositif qui permet aux jeunes de 16 ans à 25 ans
              (jusqu'à 30 ans pour les jeunes en situation de handicap) de
              s’engager dans une mission d'intérêt général de 6 à 12 mois entre
              24 et 35 heures par semaine.
            </Text>
            <Heading size="xs">✓ Quel montant ?</Heading>
            <Text>
              <Euros>600</Euros> par mois ou <Euros>710</Euros> pour les
              boursiers.
            </Text>

            <Heading size="xs">✓ Où faire sa demande ?</Heading>
            <Text>
              <Link href="https://www.service-civique.gouv.fr/" isExternal>
                https://www.service-civique.gouv.fr/
              </Link>
            </Text>

            <Heading size="xs">✓ Pour plus d’informations :</Heading>
            <Text>
              <Link
                href="https://inspire-orientation.org/blog/service-civique-et-si-je-me-lancais"
                isExternal
              >
                Service civique : et si je me lançais ?
              </Link>
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  </>
);

export const AidesMobilites = () => (
  <>
    <Heading size="sm">Aides à la mobilité</Heading>

    <Accordion allowMultiple>
      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Aides à la mobilité en France'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Aides à la mobilité en France
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Aides à la mobilité en France">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              Une aide financière pour les bacheliers et étudiants boursiers qui
              choisissent d’étudier en dehors de leur académie d’origine.
            </Text>

            <Heading size="xs">✓ Quel montant ?</Heading>

            <Text>
              <strong>Aide mobilité Parcoursup :</strong> <Euros>500</Euros> en
              une fois pour les futurs étudiants ayant bénéficié d'une bourse au
              lycée qui ont accepté un vœu sur Parcoursup hors de leur académie
              d'origine. L'examen du dossier est effectué par les Crous.
            </Text>

            <Text>
              <strong>Aide mobilité master :</strong> <Euros>1 000</Euros> en
              une fois pour les étudiants boursiers diplômés d’une licence qui
              déménagent dans une autre pour étudier en master.
            </Text>

            <Heading size="xs">✓ Où faire sa demande ?</Heading>
            <Text>
              En ligne sur :{' '}
              <Link href="https://www.messervices.etudiant.gouv.fr" isExternal>
                www.messervices.etudiant.gouv.fr
              </Link>
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Aides à la mobilité internationale'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box id="test" flex="1" textAlign="left">
            Aides à la mobilité internationale
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Aides à la mobilité internationale">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              Pour les jeunes effectuant un échange universitaire, un stage ou
              même une mission de volontariat à l’étranger.
            </Text>
            <Heading size="xs">✓ Quel montant ?</Heading>
            <Text>
              <strong>Établissement :</strong> jusqu’à <Euros>400</Euros> par
              mois pour les étudiants boursiers.
            </Text>
            <Text>
              <strong>Erasmus + :</strong> aides financières selon la
              destination (en Europe) et la durée : généralement entre{' '}
              <Euros>225</Euros> et <Euros>824</Euros> par mois
            </Text>
            <Text>
              <strong>Aide des régions et des fondations : </strong>
              des bourses spécifiques de mobilités peuvent être proposées.
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Aides SNCF'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Aides SNCF
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Aides SNCF">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              La SNCF offre des tarifs réduits aux étudiants et apprentis. Pour
              les TER notamment, il est possible de bénéficier d’un abonnement
              préférentiel pour voyager entre le domicile et le lieu d’études.
            </Text>
            <Text>
              La carte Avantage Jeune (qui coûte <Euros>50</Euros>), faite pour
              les 12-27 ans, ouvre la voie aux réductions sur les trains (TGV,
              TER, Intercités). À cela s'ajoutent des réductions sur des
              destinations européennes.
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Passeport Mobilité Etudes'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Passeport Mobilité Etudes - Outre Mer
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Passeport Mobilité Etudes - Outre Mer">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              Pour les étudiants résidant en outre-mer, désireux de poursuivre
              leurs études en France hexagonale ou dans un autre DOM, en raison
              de l’inexistence ou de la saturation de leur cursus dans leur
              territoire de résidence.
            </Text>
            <Heading size="xs">✓ Quel montant ?</Heading>
            <Text>
              Prise en charge intégrale ou partielle du transport (aérien +
              ferroviaire) de l’étudiant, de l’aéroport de son lieu de résidence
              à la gare/aéroport le plus proche de son lieu d’études. <br />-
              100 % pour les étudiants boursiers d’Etat <br />- 50 % pour les
              étudiants non boursiers d’Etat (sous conditions de ressources){' '}
              <br />
              La prise en charge s’applique sur toute la durée des études dans
              la limite d’un aller et d’un retour par an.
            </Text>
            <Heading size="xs">✓ Où faire sa demande ?</Heading>
            <Text>
              En ligne sur :{' '}
              <Link href="https://mobilite.ladom.fr/accueil" isExternal>
                www.mobilite.ladom.fr
              </Link>
              <br />
              Les territoires, départements ou régions d’outre-mer peuvent
              proposer des aides complémentaires au dispositif de LADOM.
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionButton
          onClick={() => {
            pushEvent(
              GTM_CATEGORY_KIT_DE_SURVIE,
              GTM_ACTION_VOIR_RUBRIQUE,
              'Les aides pour passer un concours'
            );
          }}
        >
          <Icon as={FileTextIcon} mr={2} />
          <Box flex="1" textAlign="left">
            Les aides pour passer un concours - Outre Mer
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
          <DefinitionBox title="Les aides pour passer un concours - Outre Mer">
            <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
            <Text>
              Le dispositif “oraux de concours” permet de prendre en charge les
              frais de déplacement (sous condition de ressources). Les
              destinations éligibles sont l’ensemble du territoire français
              (Hexagone, DROM-COM) et les pays membres de l’UE, de l’EEE ou les
              pays du bassin géographique de la collectivité de résidence.
            </Text>
            <Heading size="xs">✓ Quel montant ?</Heading>
            <Text>
              LADOM prend intégralement en charge un billet d’avion A/R, depuis
              le territoire d’outre-mer d’origine vers l’une des destinations
              éligibles.
            </Text>
            <Heading size="xs">✓ Où faire sa demande ?</Heading>
            <Text>
              En ligne sur :{' '}
              <Link href="https://mobilite.ladom.fr/accueil" isExternal>
                www.mobilite.ladom.fr
              </Link>
              <br />
            </Text>
          </DefinitionBox>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  </>
);

const KitDeSurvieVersLeSupPage = () => {
  return (
    <>
      <Heading size="sm">Les études supérieures</Heading>
      <Accordion allowMultiple>
        <AccordionItem>
          <AccordionButton
            onClick={() => {
              pushEvent(
                GTM_CATEGORY_KIT_DE_SURVIE,
                GTM_ACTION_VOIR_RUBRIQUE,
                'Infographie sur les études supérieures'
              );
            }}
          >
            <Icon as={FileImageIcon} mr={2} />
            <Box flex="1" textAlign="left">
              Infographie sur les études supérieures
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel py={2}>
            <Box maxWidth="700px" margin="auto">
              <ClickableImage
                src={InfographieEtudesSupProSrc}
                href="/img/infographies/etudes-superieurs.jpg"
                alt="Infographie études supérieurs"
              />
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <>
        <Heading size="sm">Parcoursup</Heading>
        <Accordion allowMultiple>
          {/* <AccordionItem>
            <AccordionButton
              onClick={() => {
                pushEvent(
                  GTM_CATEGORY_KIT_DE_SURVIE,
                  GTM_ACTION_VOIR_RUBRIQUE,
                  'Infographie objectif parcoursup'
                );
              }}
            >
              <Icon as={FileImageIcon} mr={2} />
              <Box flex="1" textAlign="left">
                Infographie objectif parcoursup
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel py={2}>
              <Box maxWidth="500px" margin="auto">
                <ClickableImage
                  src={InfographieParcoursupSrc}
                  href="/img/infographies/parcoursup.png"
                  alt="Infographie objectif parcoursup"
                />
              </Box>
            </AccordionPanel>
          </AccordionItem> */}
          <AccordionItem>
            <AccordionButton
              onClick={() => {
                pushEvent(
                  GTM_CATEGORY_KIT_DE_SURVIE,
                  GTM_ACTION_VOIR_RUBRIQUE,
                  'Infographie objectif parcoursup'
                );
              }}
            >
              <Icon as={FileImageIcon} mr={2} />
              <Box flex="1" textAlign="left">
                Infographie calendrier Parcoursup en 3 étapes
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel py={2}>
              <Box maxWidth="500px" margin="auto">
                <ClickableImage
                  src={InfographiesCalendrierParcoursupSrc}
                  href="/img/infographies/calendrier-parcoursup.png"
                  alt="Infographie calendrier Parcoursup en 3 étapes"
                />
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <>
            <AccordionItem>
              <AccordionButton
                onClick={() => {
                  pushEvent(
                    GTM_CATEGORY_KIT_DE_SURVIE,
                    GTM_ACTION_VOIR_RUBRIQUE,
                    'Infographie réponses des formations sur Parcoursup'
                  );
                }}
              >
                <Icon as={FileImageIcon} mr={2} />
                <Box flex="1" textAlign="left">
                  Infographie réponses des formations sur Parcoursup
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel py={2}>
                <Box maxWidth="500px" margin="auto">
                  <ClickableImage
                    src={InfographiesReponsesFormationsParcoursupSrc}
                    href="/img/infographies/rep-des-formations-sur-parcoursup.png"
                    alt="Infographie réponses des formations sur Parcoursup"
                  />
                </Box>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton
                onClick={() => {
                  pushEvent(
                    GTM_CATEGORY_KIT_DE_SURVIE,
                    GTM_ACTION_VOIR_RUBRIQUE,
                    'Infographie répondre aux propositions sélectives'
                  );
                }}
              >
                <Icon as={FileImageIcon} mr={2} />
                <Box flex="1" textAlign="left">
                  Infographie répondre aux propositions sélectives
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel py={2}>
                <Box maxWidth="500px" margin="auto">
                  <ClickableImage
                    src={InfographieRepondreFormationsSelectivesSrc}
                    href="/img/infographies/rep-aux-propositions-selectives.png"
                    alt="Infographie répondre aux propositions sélectives"
                  />
                </Box>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton
                onClick={() => {
                  pushEvent(
                    GTM_CATEGORY_KIT_DE_SURVIE,
                    GTM_ACTION_VOIR_RUBRIQUE,
                    'Infographie répondre aux propositions non-sélectives'
                  );
                }}
              >
                <Icon as={FileImageIcon} mr={2} />
                <Box flex="1" textAlign="left">
                  Infographie répondre aux propositions non-sélectives
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel py={2}>
                <Box maxWidth="500px" margin="auto">
                  <ClickableImage
                    src={InfographieRepondreFormationsNonSelectivesSrc}
                    href="/img/infographies/rep-aux-propositions-non-selectives.png"
                    alt="Infographie répondre aux propositions non-sélectives"
                  />
                </Box>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton
                onClick={() => {
                  pushEvent(
                    GTM_CATEGORY_KIT_DE_SURVIE,
                    GTM_ACTION_VOIR_RUBRIQUE,
                    "Infographie les règles d'or du projet de formation motivé"
                  );
                }}
              >
                <Icon as={FileImageIcon} mr={2} />
                <Box flex="1" textAlign="left">
                  Infographie les règles d'or du projet de formation motivé
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel py={2}>
                <Box maxWidth="500px" margin="auto">
                  <ClickableImage
                    src={InfographieReglesOrPfmSrc}
                    href="/img/infographies/regles-or-PFM.png"
                    alt="Infographie les règles d'or du projet de formation motivé"
                  />
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </>
        </Accordion>
      </>
      <>
        <AidesEtudiantes />

        <PretsEtudiants />

        <AidesMobilites />

        <Heading size="sm">Bons plans étudiants</Heading>

        <Accordion allowMultiple>
          <AccordionItem>
            <AccordionButton
              onClick={() => {
                pushEvent(
                  GTM_CATEGORY_KIT_DE_SURVIE,
                  GTM_ACTION_VOIR_RUBRIQUE,
                  'Les avantages de la carte étudiante'
                );
              }}
            >
              <Icon as={FileTextIcon} mr={2} />
              <Box flex="1" textAlign="left">
                Les avantages de la carte étudiante
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel py={2}>
              <DefinitionBox title="Les avantages de la carte étudiante">
                <Text>
                  ✓ réductions dans certains supermarchés, snacks, restaurants,
                  magasins
                  <br />✓ tarif étudiant pour les places de cinéma
                  <br />✓ accès à des musées gratuits (-26 ans)
                  <br />✓ tarif réduit ou entrée gratuite pour les salles de
                  concerts et spectacles
                  <br />✓ entrée gratuite dans les bibliothèques et
                  médiathèques, etc.
                  <br />✓ associations culturelles etc.
                </Text>
              </DefinitionBox>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton
              onClick={() => {
                pushEvent(
                  GTM_CATEGORY_KIT_DE_SURVIE,
                  GTM_ACTION_VOIR_RUBRIQUE,
                  'Se nourrir à bas coût'
                );
              }}
            >
              <Icon as={FileTextIcon} mr={2} />
              <Box flex="1" textAlign="left">
                Se nourrir à bas coût
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel py={2}>
              <DefinitionBox title="Se nourrir à bas coût">
                <Text>
                  ✓ épiceries solidaires étudiantes
                  <br />✓ associations étudiantes (paniers de légumes peu chers)
                  <br />✓ réductions avec la carte étudiante
                  <br />✓ applications anti-gaspi
                  <br />✓ restaurant Universitaire (RU) : repas à{' '}
                  <Euros>3,30</Euros> pour les étudiants, <Euros>1</Euros> pour
                  les boursiers
                  <br />✓ Cantine, cafétérias étudiantes
                </Text>
              </DefinitionBox>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton
              onClick={() => {
                pushEvent(
                  GTM_CATEGORY_KIT_DE_SURVIE,
                  GTM_ACTION_VOIR_RUBRIQUE,
                  'Pass’sport'
                );
              }}
            >
              <Icon as={FileTextIcon} mr={2} />
              <Box flex="1" textAlign="left">
                Pass’sport
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel py={2}>
              <DefinitionBox title="Pass’sport">
                <Heading size="xs">✓ Qu’est-ce que c’est ?</Heading>
                <Text>
                  Une aide de <Euros>50</Euros> qui permet aux jeunes de
                  s'inscrire dans une association sportive.
                </Text>
                <Heading size="xs">✓ Qui en bénéficie ?</Heading>
                <Text>
                  <UnorderedList>
                    <ListItem>
                      Les jeunes de 6 à 17 ans qui bénéficient de l’allocation
                      de rentrée scolaire
                    </ListItem>
                    <ListItem>
                      Les jeunes de 6 à 19 ans qui bénéficient de l’allocation
                      d’éducation de l’enfant handicapé
                    </ListItem>
                    <ListItem>
                      Les jeunes de 16 à 30 ans qui bénéficient de l’allocation
                      aux adultes handicapés
                    </ListItem>
                    <ListItem>
                      Les étudiants âgés au plus de 28 ans qui bénéficie de
                      l'Etat de l'enseignement supérieur pour sous conditions de
                      ressources, d’une aide annuelle du CROUS ou d’une bourse
                      régionale pour les formations sanitaires et sociales pour
                      l’année universitaire 2022 – 2023.
                    </ListItem>
                  </UnorderedList>
                </Text>
              </DefinitionBox>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton
              onClick={() => {
                pushEvent(
                  GTM_CATEGORY_KIT_DE_SURVIE,
                  GTM_ACTION_VOIR_RUBRIQUE,
                  'Matériel à bas coût'
                );
              }}
            >
              <Icon as={FileTextIcon} mr={2} />
              <Box flex="1" textAlign="left">
                Matériel à bas coût
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel py={2}>
              <DefinitionBox title="Matériel à bas coût">
                <Text>
                  ✓ bourse aux livres
                  <br />✓ associations étudiantes
                  <br />✓ sites de vente entre particuliers
                  <br />✓ promotions étudiantes dans les magasins d’électronique
                  à la rentrée scolaire
                  <br />✓ réseaux sociaux d’étudiants et anciens étudiants
                </Text>
              </DefinitionBox>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <TravaillerPendantSesEtudes />
      </>
    </>
  );
};

export default KitDeSurvieVersLeSupPage;
