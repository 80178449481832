import {
  Box,
  Checkbox,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { validateSchema } from '@inspire/data/helpers/schemas';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import { FieldArray, Form, Formik, FormikConfig, FormikValues } from 'formik';
import { useMyProfile, usePistes, useUser } from 'lib/hooks';
import { FormControl, InputControl, SelectControl } from 'formik-chakra-ui';
import { take } from 'lodash';
import { getPistesReco } from '@inspire/data/helpers/algo';
import Link from 'components/general/Link';
import { pistePath } from 'lib/paths';
import PisteItem from 'components/piste/PisteItem';

export const experienceProOptions = {
  stage: 'Stage',
  alternance: 'Alternance',
};

export const dureeOptions = {
  moinsUnAn: 'Entre 6 mois et 1 an',
  unAn: '1 an',
  deuxAns: '2 ans',
  troisAns: '3 ans',
};

interface formationComparaisonItemInterface {
  pisteId: string;
  debouches: string;
  duree: string;
  cout: string;
  experiencePro: [string];
}

export type formationComparaisonsInterface = {
  [key: number]: formationComparaisonItemInterface;
};

const formatValues = (values) => {
  return {
    formationComparaisons: Object.values(
      values.formationComparaisons as formationComparaisonsInterface
    ),
  };
};

export const ComparerFormations = ({ goNext }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isProf } = useUser();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const { pistes } = usePistes();

  let pistesReco = [];

  if (pistes) {
    if (myProfile?.formationComparaisons?.length) {
      const pisteIds = myProfile.formationComparaisons.map(
        (value) => value.pisteId
      );
      pistesReco = pistes.filter((piste) => pisteIds.includes(piste._id));
    } else {
      pistesReco = isProf
        ? take(pistes, 3)
        : take(getPistesReco(myProfile.algoResults, pistes), 3);
    }
  }

  const schema = LyceenBacProProfileSchema.pick(
    'formationComparaisons',
    'formationComparaisons.$'
  ).requireFields();

  const initialValues = {
    formationComparaisons: {},
  };

  pistesReco.map((piste, index) => {
    const previousValue = myProfile?.formationComparaisons?.find(
      (element) => element.pisteId === piste._id
    );
    initialValues.formationComparaisons[piste._id] = {
      pisteId: piste._id,
      duree: previousValue?.duree || '',
      cout: previousValue?.cout || '',
      experiencePro: previousValue?.experiencePro || [],
      debouches: previousValue?.debouches || '',
      correspond: previousValue?.correspond || '',
      correspondpas: previousValue?.correspondpas || '',
      order: previousValue?.order >= 0 ? previousValue?.order : index,
    };
  });

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, formatValues(values)),
    onSubmit: (values) => setAndRefetchMyProfile(formatValues(values)),
  };

  return (
    <Formik {...formik}>
      {({ values }) => (
        <Form>
          <Text textAlign="center">
            Consulte les pistes et remplis le tableau pour comparer les
            formations : <span style={{ color: 'red' }}>*</span>
          </Text>

          <SimpleGrid columns={{ sm: 1, lg: 3 }} spacing="30px" mt="10">
            {pistesReco.map((piste) => (
              <Box key={`piste_${piste.slug}`}>
                <Link
                  href={pistePath(piste.slug)}
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                >
                  <PisteItem piste={piste} showGauge={false} />
                </Link>
              </Box>
            ))}
          </SimpleGrid>

          <Tabs
            variant="soft-rounded"
            colorScheme="gray"
            isFitted={!isMobile}
            mt={10}
            defaultIndex={0}
            isLazy
            orientation={isMobile ? 'vertical' : 'horizontal'}
          >
            <TabList>
              <Tab
                textAlign={isMobile ? 'left' : 'center'}
                justifyContent={isMobile ? 'left' : 'center'}
              >
                Durée
              </Tab>
              <Tab
                textAlign={isMobile ? 'left' : 'center'}
                justifyContent={isMobile ? 'left' : 'center'}
              >
                Coût
              </Tab>
              <Tab
                textAlign={isMobile ? 'left' : 'center'}
                justifyContent={isMobile ? 'left' : 'center'}
              >
                Expérience pro
              </Tab>
              <Tab
                textAlign={isMobile ? 'left' : 'center'}
                justifyContent={isMobile ? 'left' : 'center'}
              >
                Débouchés qui m’intéressent
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel id="duree" textAlign={'center'}>
                {pistesReco.map((piste) => (
                  <SelectControl
                    key={`formationComparaisons_${piste._id}_duree`}
                    name={`formationComparaisons[${piste._id}].duree`}
                    label={piste.name}
                    isRequired
                    mb={5}
                    selectProps={!isMobile && { width: '50%' }}
                  >
                    <option value=""></option>
                    {Object.entries(dureeOptions).map(([key, label]) => (
                      <option
                        key={`formationComparaisons_${piste._id}_duree_${key}`}
                        value={key}
                      >
                        {label}
                      </option>
                    ))}
                  </SelectControl>
                ))}
              </TabPanel>

              <TabPanel>
                {pistesReco.map((piste) => (
                  <InputControl
                    key={`formationComparaisons_${piste._id}_cout`}
                    name={`formationComparaisons[${piste._id}].cout`}
                    label={piste.name}
                    isRequired
                    mb={5}
                  />
                ))}
              </TabPanel>

              <TabPanel>
                {pistesReco.map((piste) => (
                  <FormControl
                    key={`formationComparaisons_${piste._id}_experiencePro`}
                    name={`formationComparaisons[${piste._id}].experiencePro`}
                    mb="4"
                  >
                    <Box fontWeight="bold" mb="2">
                      {piste.name} <span style={{ color: 'red' }}>*</span>
                    </Box>
                    <SimpleGrid columns={{ sm: 1, md: 4 }}>
                      <FieldArray
                        name={`formationComparaisons[${piste._id}].experiencePro`}
                        render={(arrayHelpers) =>
                          Object.entries(experienceProOptions).map(
                            ([key, label]) => (
                              <Box
                                key={`formationComparaisons_${piste._id}_experiencePro_${key}`}
                              >
                                <Checkbox
                                  value={key}
                                  mb={4}
                                  isChecked={values?.formationComparaisons?.[
                                    piste._id
                                  ]?.experiencePro?.includes(key)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      arrayHelpers.push(key);
                                      return;
                                    }
                                    const index =
                                      values?.formationComparaisons?.[
                                        piste._id
                                      ]?.experiencePro?.indexOf(key);
                                    arrayHelpers.remove(index);
                                  }}
                                >
                                  {label}
                                </Checkbox>
                              </Box>
                            )
                          )
                        }
                      />
                    </SimpleGrid>
                  </FormControl>
                ))}
              </TabPanel>

              <TabPanel>
                {pistesReco.map((piste) => (
                  <InputControl
                    key={`formationComparaisons_${piste._id}_debouches`}
                    name={`formationComparaisons[${piste._id}].debouches`}
                    label={piste.name}
                    isRequired
                    mb={5}
                  />
                ))}
              </TabPanel>
            </TabPanels>
          </Tabs>

          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={
              isProf ||
              formatValues(values).formationComparaisons.filter(
                (value) =>
                  value.pisteId && value.duree && value.cout && value.debouches
              ).length === 3
            }
          />
        </Form>
      )}
    </Formik>
  );
};
