import {
  Box,
  Checkbox,
  Heading,
  Radio,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { validateSchema } from '@inspire/data/helpers/schemas';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import { FieldArray, Form, Formik, FormikConfig, FormikValues } from 'formik';
import { useMyProfile, usePistes, useUser } from 'lib/hooks';
import {
  FormControl,
  RadioGroupControl,
  TextareaControl,
} from 'formik-chakra-ui';
import { take } from 'lodash';
import { getPistesReco } from '@inspire/data/helpers/algo';
import Link from 'components/general/Link';
import LinkButton from 'components/general/LinkButton';
import GradeControl from 'components/forms/controls/GradeControl';
import Panel from '@inspire/ui/general/Panel';
import { cdnURL } from '@inspire/util/cdn';
import {
  RecapCheckbox,
  RecapRadio,
  RecapTextArea,
} from 'components/activites/recap-helpers';

const formationSelectiveOptions = {
  oui: 'Oui',
  non: 'Non',
  neSaisPas: 'Je ne sais pas',
};

const pointsFortsOptions = {
  resultatsScolaires: 'Mes résultats scolaires',
  competences: 'Mes compétences, méthodes de travail ou savoir-faire',
  savoirEtre: 'Mes savoir-être',
  motivation: 'Ma motivation et la cohérence avec mon projet',
  engagement: "Mon engagement, mes activités et centres d'intérêt",
  pasExamenCandidatures: 'La formation n’examine pas les candidatures',
};

export const MeRenseignerSurMaFormation = ({ goNext }) => {
  const { isProf } = useUser();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const { pistes } = usePistes();

  let pistesReco = [];

  if (pistes) {
    if (myProfile?.formationComparaisons?.length) {
      const pisteIds = myProfile.formationComparaisons.map(
        (value) => value.pisteId
      );
      pistesReco = pistes.filter((piste) => pisteIds.includes(piste._id));
    } else {
      pistesReco = isProf
        ? take(pistes, 3)
        : take(getPistesReco(myProfile.algoResults, pistes), 3);
    }
  }

  const schema = LyceenBacProProfileSchema.pick(
    'formationChoixPiste',
    'formationEtablissements',
    'formationSelective',
    'formationPointsForts',
    'formationMoyenne',
    'formationMatiereBonsResultats',
    'formationSavoirsEtre',
    'formationEvaluationMotivation',
    'formationCompetencesTransversales',
    'formationMesPointsForts',
    'formationMesPointsAmelioration',
    'formationMesExperiencesUtiles'
  ).requireFields();

  const initialValues = {
    formationChoixPiste: myProfile?.formationChoixPiste,
    formationEtablissements: myProfile?.formationEtablissements,
    formationSelective: myProfile?.formationSelective,
    formationPointsForts: myProfile?.formationPointsForts,
    formationMoyenne: myProfile?.formationMoyenne,
    formationMatiereBonsResultats: myProfile?.formationMatiereBonsResultats,
    formationSavoirsEtre: myProfile?.formationSavoirsEtre,
    formationEvaluationMotivation: myProfile?.formationEvaluationMotivation,
    formationCompetencesTransversales:
      myProfile?.formationCompetencesTransversales,
    formationMesPointsForts: myProfile?.formationMesPointsForts,
    formationMesPointsAmelioration: myProfile?.formationMesPointsAmelioration,
    formationMesExperiencesUtiles: myProfile?.formationMesExperiencesUtiles,
  };

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(values),
  };

  return (
    <Formik {...formik}>
      {({ values, setFieldValue }) => (
        <Form>
          <Text textAlign="center" fontStyle="italic">
            Dans cette étape, tu vas travailler avec l’exemple d’une formation
            parmi les pistes recommandées. Tu pourras recommencer cette étape
            avec d’autres formations si tu le souhaites.
          </Text>

          <FormControl
            name={'formationChoixPiste'}
            label={
              'Choisis une piste parmi celles que tu as vues précédemment :'
            }
            isRequired
          >
            <SimpleGrid columns={[1, null, 3]} spacing={10}>
              {pistesReco.map((piste) => (
                <Box key={`piste_${piste.slug}`}>
                  <Link
                    href="#"
                    style={{ textDecoration: 'none' }}
                    onClick={(event) => {
                      event.preventDefault();
                      setFieldValue('formationChoixPiste', piste._id);
                    }}
                  >
                    <Box as={Panel} p={0} borderRadius={'10px'}>
                      <Box
                        pos="relative"
                        height="180px"
                        padding={[4, null, 8]}
                        overflow="hidden"
                        borderRadius={'4px'}
                        border="4px solid"
                        borderColor={
                          values.formationChoixPiste === piste._id
                            ? 'yellow.500'
                            : 'white'
                        }
                        _after={{
                          boxShadow:
                            'inset 0 0 0 1px rgba(255, 255, 255, 0.15)',
                          border: '1px solid rgba(0, 0, 0, 0.15)',
                          display: 'block',
                          position: 'absolute',
                          top: '0px',
                          right: '0px',
                          bottom: '0px',
                          left: '0px',
                          content: '""',
                          zIndex: 1,
                        }}
                      >
                        <Box
                          pos="absolute"
                          top={0}
                          left={0}
                          right={0}
                          bottom={0}
                          _after={{
                            position: 'absolute',
                            top: '0',
                            bottom: '0',
                            left: '0',
                            right: '0',
                            content: '""',
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                          }}
                        >
                          <Box
                            pos="absolute"
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            backgroundImage={cdnURL(piste.coverPicture)}
                            backgroundSize={'cover'}
                            filter={
                              !values.formationChoixPiste ||
                              values.formationChoixPiste === piste._id
                                ? 'none'
                                : 'grayscale(100%)'
                            }
                          ></Box>
                        </Box>
                        <Heading
                          size="md"
                          color="white"
                          textShadow="1px 1px 0px rgba(0, 0, 0, 0.6)"
                          pos="relative"
                          fontFamily="Roboto Condensed"
                          m={0}
                        >
                          {piste.name}
                        </Heading>
                      </Box>
                    </Box>
                  </Link>
                </Box>
              ))}
            </SimpleGrid>
          </FormControl>

          <Box m="10" textAlign="center">
            <LinkButton
              href="https://dossierappel.parcoursup.fr/Candidat/carte"
              target="_blank"
              fontWeight="bold"
            >
              Parcoursup
            </LinkButton>
          </Box>

          <TextareaControl
            label="Recherche la formation sur Parcoursup, et liste 3 établissements qui la proposent (essaie d’en trouver un près de chez toi)."
            name="formationEtablissements"
            isRequired={true}
            mb="10"
          />

          <Heading as={'h2'} fontSize={20}>
            Les conditions d’admission
          </Heading>

          <Text>
            👉 Consulte la rubrique “Comprendre les critères d’analyse des
            candidatures”, et réponds aux questions suivantes :
          </Text>

          <RadioGroupControl
            name="formationSelective"
            label="La formation est-elle sélective ?"
            stackProps={{
              direction: ['column', null, 'column'],
              spacing: [1, null, 3],
            }}
            isRequired
            mb="10"
          >
            {Object.entries(formationSelectiveOptions).map(([key, label]) => (
              <Radio key={key} value={key}>
                {label}
              </Radio>
            ))}
          </RadioGroupControl>

          <FormControl
            name="formationPointsForts"
            label={
              <Text fontWeight={'bold'}>
                Quels sont tes points forts pour cette formation ?{' '}
                <span style={{ color: 'red' }}>*</span>
              </Text>
            }
            mb="10"
          >
            <FieldArray
              name={`formationPointsForts`}
              render={(arrayHelpers) =>
                Object.entries(pointsFortsOptions).map(([key, label]) => (
                  <Box key={`formationPointsForts_${key}`}>
                    <Checkbox
                      value={key}
                      mb={4}
                      isChecked={values?.formationPointsForts?.includes(key)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          arrayHelpers.push(key);
                          return;
                        }

                        arrayHelpers.remove(
                          values?.formationPointsForts?.indexOf(key)
                        );
                      }}
                    >
                      {label}
                    </Checkbox>
                  </Box>
                ))
              }
            />
          </FormControl>

          <FormControl
            name="formationMoyenne"
            label="Quelle est la moyenne minimum attendue ?"
            isRequired
            maxW="400px"
            w="full"
            mb="10"
          >
            <GradeControl
              labelProps={{ fontWeight: 'normal !important' }}
              name="formationMoyenne"
              flex={1}
              pt={1.5}
            />
          </FormControl>

          <TextareaControl
            label="Dans quelle domaine ou matière faut-il absolument avoir de bons résultats ?"
            name="formationMatiereBonsResultats"
            isRequired={true}
            mb="10"
          />

          <TextareaControl
            label="Quel(s) savoir(s)-être comptent le plus ?"
            name="formationSavoirsEtre"
            isRequired={true}
            mb="10"
          />

          <TextareaControl
            label="Comment est évaluée ta motivation ?"
            name="formationEvaluationMotivation"
            isRequired={true}
            mb="10"
          />

          <TextareaControl
            label="Quels sont les attendus de la formation en termes de compétences transversales ?"
            name="formationCompetencesTransversales"
            isRequired={true}
            mb="10"
          />

          <Heading as={'h2'} fontSize={20}>
            Pour faire le point :
          </Heading>

          <TextareaControl
            label="Quels sont tes points forts pour cette formation ?"
            name="formationMesPointsForts"
            isRequired={true}
            mb="10"
          />

          <TextareaControl
            label="Quels sont tes points d’amélioration pour remplir les conditions ?"
            name="formationMesPointsAmelioration"
            isRequired={true}
            mb="10"
          />

          <TextareaControl
            label="Quels sont tes centres d’intérêt ou expériences qui te seront utiles pour la formation visée ?"
            name="formationMesExperiencesUtiles"
            isRequired={true}
            mb="10"
          />

          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={
              isProf ||
              (values?.formationPointsForts?.length >= 1 &&
                values?.formationChoixPiste &&
                values?.formationEtablissements &&
                values?.formationSelective &&
                values?.formationMoyenne &&
                values?.formationMatiereBonsResultats &&
                values?.formationSavoirsEtre &&
                values?.formationEvaluationMotivation &&
                values?.formationCompetencesTransversales &&
                values?.formationMesPointsForts &&
                values?.formationMesPointsAmelioration &&
                values?.formationMesExperiencesUtiles)
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export const RecapMeRenseignerSurMaFormation = ({ profile }) => {
  const { pistes } = usePistes();

  return (
    <>
      <Text fontWeight={'bold'}>
        Choisis une piste parmi celles que tu as vues précédemment :
      </Text>
      <Text>
        {pistes &&
          pistes.find((piste) => piste._id === profile?.formationChoixPiste)
            ?.name}
      </Text>

      <RecapTextArea
        header="Recherche la formation sur Parcoursup, et liste 3 établissements qui la proposent (essaie d’en trouver un près de chez toi)."
        fieldName="formationEtablissements"
        profile={profile}
      />

      <RecapRadio
        profile={profile}
        fieldName="formationSelective"
        header="La formation est-elle sélective ?"
        options={formationSelectiveOptions}
      />

      <RecapCheckbox
        header="Quels sont tes points forts pour cette formation ?"
        fieldName="formationPointsForts"
        options={pointsFortsOptions}
        profile={profile}
      />

      <RecapTextArea
        header="Quelle est la moyenne minimum attendue ?"
        fieldName="formationMoyenne"
        profile={profile}
      />

      <RecapTextArea
        header="Dans quelle domaine ou matière faut-il absolument avoir de bons résultats ?"
        fieldName="formationMatiereBonsResultats"
        profile={profile}
      />

      <RecapTextArea
        header="Quel(s) savoir(s)-être comptent le plus ?"
        fieldName="formationSavoirsEtre"
        profile={profile}
      />

      <RecapTextArea
        header="Comment est évaluée ta motivation ?"
        fieldName="formationEvaluationMotivation"
        profile={profile}
      />

      <RecapTextArea
        header="Quels sont les attendus de la formation en termes de compétences transversales ?"
        fieldName="formationCompetencesTransversales"
        profile={profile}
      />

      <RecapTextArea
        header="Quels sont tes points forts pour cette formation ?"
        fieldName="formationMesPointsForts"
        profile={profile}
      />

      <RecapTextArea
        header="Quels sont tes points d’amélioration pour remplir les conditions ?"
        fieldName="formationMesPointsAmelioration"
        profile={profile}
      />

      <RecapTextArea
        header="Quels sont tes centres d’intérêt ou expériences qui te seront utiles pour la formation visée ?"
        fieldName="formationMesExperiencesUtiles"
        profile={profile}
      />
    </>
  );
};
