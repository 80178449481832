import { Box, Checkbox, SimpleGrid, Text } from '@chakra-ui/react';
import Select from '@inspire/ui/chakra/forms/Select';
import { validateSchema } from '@inspire/data/helpers/schemas';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import { FieldArray, Form, Formik, FormikConfig, FormikValues } from 'formik';
import { useMyProfile, useUser } from 'lib/hooks';
import { useState } from 'react';
import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';
import { FormControl, TextareaControl } from 'formik-chakra-ui';
import {
  RecapCheckbox,
  RecapTextArea,
} from 'components/activites/recap-helpers';

const competencesTransversales = [
  {
    label: 'Agriculture',
    competences:
      'Intégration au groupe ; Rigueur et fiabilité ; Persévérance ; Autonomie ; Gestion du stress ; Capacité d’analyse',
  },
  {
    label: 'Agroéquipement',
    competences:
      'Communication écrite ; Communication numérique ; Rigueur et fiabilité ; Gestion du stress ; Gestion du temps ;  Capacité d’analyse',
  },
  {
    label: 'Architecture, urbanisme, paysage',
    competences:
      'Communication écrite ; Rigueur et fiabilité ; Partage et réception d’avis ; Imagination ; Capacité d’analyse',
  },
  {
    label: 'Commerce, distribution',
    competences:
      'Écoute active ; Diplomatie ; Autonomie ; Gestion du stress ; Imagination ; Gestion du temps',
  },
  {
    label: 'Comptabilité, gestion',
    competences:
      'Rigueur et fiabilité ; Persévérance ; Éthique ; Organisation ;  Capacité d’analyse',
  },
  {
    label: 'Filière bois',
    competences:
      'Travail en équipe ; Rigueur et fiabilité ; Persévérance ; Autonomie ; Gestion du temps ; Capacité d’analyse',
  },
  {
    label: 'Hôtellerie, restauration',
    competences:
      'Communication orale ; Intégration au groupe ; Rigueur et fiabilité ; Diplomatie ; Gestion du stress ; Ouverture socioculturelle',
  },
  {
    label: 'Industrie alimentaire',
    competences:
      'Travail en équipe ; Rigueur et fiabilité ; Autonomie ; Imagination ; Organisation ; Capacité d’analyse',
  },
  {
    label: 'Logistique et transport',
    competences:
      'Communication numérique ; Intégration au groupe ; Rigueur et fiabilité ; Gestion du stress ; Réactivité ; Organisation',
  },
  {
    label: 'Social',
    competences:
      'Intégration au groupe ; Écoute active ; Éthique ; Gestion du stress ; Capacité d’analyse',
  },
  {
    label: 'Tourisme',
    competences:
      'Communication orale ; Travail en équipe ; Partage et réception de retours ; Ouverture socioculturelle ; Capacité d’analyse ; Organisation',
  },
];

export const SecteursEtCompetences = ({ goNext }) => {
  const { isProf } = useUser();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });
  const [secteur, setSecteur] = useState();

  const schema = LyceenBacProProfileSchema.pick(
    'secteursInterets',
    'secteursCompetencesTransversales',
    'secteursCompetencesADevelopper'
  ).requireFields();
  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      secteursInterets: myProfile?.secteursInterets,
      secteursCompetencesTransversales:
        myProfile?.secteursCompetencesTransversales,
      secteursCompetencesADevelopper: myProfile?.secteursCompetencesADevelopper,
    },
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(schema.clean(values)),
  };

  return (
    <Formik {...formik}>
      {({ values }) => (
        <Form>
          <SummaryFrame label="Définition">
            <Text>
              <b>Compétences transversales :</b> Capacités acquises durant
              toutes les expériences vécues (y compris en dehors de l’école et /
              ou du travail). Elles sont aussi appelées compétences
              transversales car elles peuvent être utilisées dans des contextes
              très différents. Par exemple : l’autonomie et le travail en
              équipe. Elles sont utiles et mobilisées aussi bien pour la
              poursuite d’études que dans l’activité professionnelle. Les
              compétences transversales se distinguent des compétences
              techniques, c’est-à-dire des connaissances et savoir-faire
              spécifiques à un métier ou à un secteur d’activité. Par exemple :
              la maîtrise d’une langue étrangère ou d’un logiciel informatique.
            </Text>
          </SummaryFrame>

          <Text fontWeight="bold">
            Consulte les compétences transversales nécessaires dans les 11
            secteurs d’activité proposés.
          </Text>

          <Text fontStyle="italic">
            Il existe beaucoup d’autres secteurs d’activités, nous te proposons
            de t’exercer en explorant ceux-ci. Tu pourras explorer d’autres
            secteurs si tu le souhaite plus tard.
          </Text>

          <Box w="300px">
            <Select
              openMenuOnFocus={true}
              isSearchable={false}
              options={competencesTransversales}
              onChange={(selectedOption) => setSecteur(selectedOption.label)}
            />
          </Box>

          <SimpleGrid columns={[3]} spacing="6" mt="6" ml="4">
            {secteur &&
              competencesTransversales
                .filter((value) => value.label === secteur)[0]
                .competences.split(';')
                .map((value) => (
                  <Box key={value}>
                    <b>{value}</b>
                  </Box>
                ))}
          </SimpleGrid>

          <FormControl
            name="secteursInterets"
            label={
              <Text fontWeight="bold">
                Sélectionne 3 secteurs qui t’intéressent.{' '}
                <span style={{ color: 'red' }}>*</span>
              </Text>
            }
            mt="10"
          >
            <SimpleGrid columns={[3]}>
              <FieldArray
                name="secteursInterets"
                render={(arrayHelpers) =>
                  Object.entries(competencesTransversales).map(
                    ([key, value]) => (
                      <Box key={`competence_${key}`}>
                        <Checkbox
                          value={value.label}
                          mb={4}
                          isChecked={values?.secteursInterets?.includes(
                            value.label
                          )}
                          disabled={
                            values?.secteursInterets?.length === 3 &&
                            !values?.secteursInterets?.includes(value.label)
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              arrayHelpers.push(value.label);
                              return;
                            }

                            const index = values?.secteursInterets?.indexOf(
                              value.label
                            );
                            arrayHelpers.remove(index);
                          }}
                        >
                          {value.label}
                        </Checkbox>
                      </Box>
                    )
                  )
                }
              />
            </SimpleGrid>
          </FormControl>

          <TextareaControl
            label="As-tu déjà utilisé des compétences transversales qui peuvent te servir dans le secteur ou le métier qui t’intéresse actuellement ? Lesquelles ?"
            name="secteursCompetencesTransversales"
            isRequired={true}
            mt="10"
          />

          <TextareaControl
            label="Quelles compétences liées à ces secteurs penses-tu devoir développer ?"
            name="secteursCompetencesADevelopper"
            isRequired={true}
            mt="10"
          />

          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={
              isProf ||
              (values?.secteursInterets?.length === 3 &&
                values?.secteursCompetencesTransversales &&
                values?.secteursCompetencesADevelopper)
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export const RecapSecteursEtCompetences = ({ profile }) => (
  <Box>
    <RecapCheckbox
      profile={profile}
      fieldName="secteursInterets"
      header="Sélectionne 3 secteurs qui t’intéresse."
      options={Object.fromEntries(
        Object.entries(competencesTransversales).map(([, value]) => [
          value.label,
          value.label,
        ])
      )}
    />

    <RecapTextArea
      profile={profile}
      header="As-tu déjà utilisé des compétences transversales qui peuvent te servir dans le secteur ou le métier qui t’intéresse actuellement ? Lesquelles ?"
      fieldName="secteursCompetencesTransversales"
    />
  </Box>
);
