import { Box, Radio, Text } from '@chakra-ui/react';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { RadioGroupControl } from 'formik-chakra-ui';
import { cdnURL } from '@inspire/util/cdn';

import {
  validateSchema,
  initFormikValuesFromSchema,
  convertNumbersToStrings,
} from '@inspire/data/helpers/schemas';
import { BilanModuleSchema } from '@inspire/data/schemas/user/profile/lyceen';

import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import { useMyProfile, useSetBilanModule } from 'lib/hooks';

export const bilanOptions = [
  {
    eleveLabel: '🙅‍♀️ Pas du tout !',
    profLabel: 'Non',
    // See comment in the schema for -2,0,2 explanation
    value: -2,
    profUrl: cdnURL('/twemoji-13.1.0/1f622.png'),
    eleveUrl: cdnURL('/twemoji-13.1.0/1f641.png'),
  },
  {
    eleveLabel: '🤔 Pas tout à fait...',
    profLabel: 'Un peu',
    value: 0,
    profUrl: cdnURL('/twemoji-13.1.0/1f610.png'),
    eleveUrl: cdnURL('/twemoji-13.1.0/1f610.png'),
  },
  {
    eleveLabel: '🤩 Tout à fait !',
    profLabel: 'Oui',
    value: 2,
    profUrl: cdnURL('/twemoji-13.1.0/1f60d.png'),
    eleveUrl: cdnURL('/twemoji-13.1.0/1f60d.png'),
  },
];

const BilanModule = ({ _module, goNext }) => {
  const { myProfile, refetchMyProfile } = useMyProfile();
  const { setBilanModule } = useSetBilanModule(_module.moduleId, {
    onSuccess: async () => {
      await refetchMyProfile();
      goNext();
    },
  });

  const questions = _module.bilanQuestions;

  const moduleBilan = myProfile.bilansModules?.find(
    (bm) => bm.moduleId === _module.moduleId
  );

  const schema = BilanModuleSchema.pick(
    'questions',
    'questions.$'
  ).requireFields();

  const previousAnswers = convertNumbersToStrings(
    initFormikValuesFromSchema(schema, moduleBilan),
    ['questions.$.answer']
  );

  const combinedPreviousAndInitValues = questions.map((q) => ({
    id: q.id,
    answer: previousAnswers.questions?.find((x) => x.id === q.id)?.answer,
  }));

  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      questions: combinedPreviousAndInitValues,
      'questions.$': '',
    },
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) =>
      setBilanModule(
        schema.clean(values, { mutate: false, autoConvert: true }).questions
      ),
  };

  return (
    <Formik {...formik}>
      {({ isSubmitting }) => (
        <Form>
          <Text mb={5}>À la suite de ce module...</Text>
          {questions.map((q, i) => (
            <Box key={q.id} mb={5}>
              <RadioGroupControl
                name={`questions[${i}].answer`}
                label={q.text}
                stackProps={{
                  direction: ['column', null, 'column'],
                  spacing: [1, null, 3],
                }}
                isRequired
              >
                {bilanOptions.map((bo) => (
                  <Radio key={bo.value} value={String(bo.value)}>
                    {/* {
                      <Image
                        src={bo.eleveUrl}
                        alt="Smiley"
                        boxSize={5}
                        mr={2}
                        verticalAlign="middle"
                        pos="relative"
                        top="-2px"
                      />
                    } */}
                    {bo.eleveLabel}
                  </Radio>
                ))}
              </RadioGroupControl>
            </Box>
          ))}
          <FormActiviteFooter isLoading={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default BilanModule;
